import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useNavigate, useParams } from 'react-router-dom'
import { RootStore } from '../../redux/store'
import { EnhancementDay, EnhancementGetResponse } from '../../models/enhancement'
import { TotalHoursCard } from './TotalHours/TotalHoursCard'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import Card from '../../shared/layout/Card'
import { v2MyActionsService } from '../../services/myActionsServiceV2'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import NoDataFound from '../../shared/UI/NoDataFound'
import { EmployeeDetailsResponse } from '../../types/employee'
import { hasUserAccessToRoute } from '../../utils/app-utils'
import Approval from './Approval/Approval'

/**
 * Calculate total hours to 2 decimal points
 */
export const totalHours = (days: Array<EnhancementDay> | undefined) => {
  let total = 0
  days?.forEach(day => {
    total += day.checked ? Number(day.hours) : 0
  })
  return total.toFixed(2)
}

export function EnhancementRequest() {
  const { id: enhancementId } = useParams<{ id: string }>()
  const [enhancement, setEnhancement] = useState<EnhancementGetResponse>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  /*
   * Start feature flag code
   * Check if user has the permissions to view this page and the feature toggle is on
   */
  const navigate = useNavigate()
  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const featureToggles = useSelector((state: RootStore) => state.featureToggles)

  // Check if user has the permissions to view this page and the feature toggle is on
  useEffect(() => {
    if (
      userPermissions?.length > 0 &&
      featureToggles?.enhancement !== undefined &&
      !hasUserAccessToRoute('/enhancements', userPermissions, employeeDetails.isContractor)
    ) {
      navigate('/dashboard')
    }
  }, [employeeDetails.isContractor, featureToggles, navigate, userPermissions])
  /*
   * End feature flag code
   */

  useEffect(() => {
    if (enhancementId) {
      setIsLoading(true)
      v2MyActionsService
        .getEnhancementForManager(Number(enhancementId))
        .then((response: EnhancementGetResponse) => {
          setEnhancement(response)
        })
        .catch(e => {
          console.error('Failed to getEnhancementForManager', e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [enhancementId])
  return enhancement ? (
    <>
      <MyActionsPageHeader title={`${enhancement?.enhancementType || ''} Request`} hideNewRequest />
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              xl={6}
              style={{
                display: 'flex',
                flexFlow: 'column',
                flex: '1 1 auto',
              }}
            >
              <TotalHoursCard {...enhancement} />
            </Grid>
            <Grid
              item
              xs={12}
              xl={6}
              style={{
                display: 'flex',
                flexFlow: 'column',
                flex: '1 1 auto',
              }}
            >
              <Card title="Summary" />
            </Grid>
            <Grid item xs={12}>
              <Card title="Wall Chart" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Approval {...enhancement} />
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <NoDataFound show={!isLoading} />
      <LoadingIndicator show={isLoading} />
    </>
  )
}
