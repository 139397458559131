import { Checkbox, Grid, Stack, TextField } from '@mui/material'
import { format } from 'date-fns'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PageContext } from '../../../context/MyRequestsPageContext'
import { RequestStatus } from '../../../models'
import {
  SetShowModalPayload,
  hideModal,
  showModalDialog,
} from '../../../redux/reducers/appSettingsReducer'
import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from '../../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../../redux/store'
import { availabilityService } from '../../../services/availabilityService'
import { manualRequestsService } from '../../../services/myActionsService'
import Alert from '../../../shared/UI/Alert/Alert'
import Button from '../../../shared/UI/Button'
import DrawerFooter from '../../../shared/UI/DrawerFooter'
import HeadingTwo from '../../../shared/UI/HeadingTwo'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import Modal from '../../../shared/UI/Modal'
import Paragraph from '../../../shared/UI/Paragraph'
import TimePicker from '../../../shared/UI/TimePicker'
import { BaseResponse } from '../../../types/base-response'
import { ChangeRequest } from '../../../types/change-request'
import { PendingRequestUpdateRequest } from '../../../types/pending-request-update-request'
import { RequestCancelRequest } from '../../../types/request-cancel-request'
import { MyRequestDetailResponse, RequestDayRequest } from '../../../types/requests'
import { absoluteDate, formatTime } from '../../../utils/date-utils'
import UserErrorMessage from '../../../utils/errorFilter'
import { HoursContainer, RequestDaysTextField } from '../../MultiDayRequest/components'

type Props = {
  id: number
  readonlyOverride?: boolean
  requestType: string
  closeViewEditDrawer: () => void
  closeLoading?: boolean
  isHolidayManualRequest?: boolean
  isCancellation?: boolean
}

function ViewEditOccurrence({
  id,
  readonlyOverride,
  requestType,
  closeViewEditDrawer,
  closeLoading = false,
  isHolidayManualRequest,
  isCancellation,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showRequestSummary, setShowRequestSummary] = useState<boolean>(true)
  const [requestDetail, setRequestDetail] = useState<
    (MyRequestDetailResponse & { requestDaysCr?: RequestDayRequest[] }) | null
  >(null)
  const [initialRequestDetail, setInitialRequestDetail] = useState<MyRequestDetailResponse | null>(
    null
  )
  const [formattedShiftStartTime, setFormattedShiftStartTime] = useState<Date>()
  const [totalHours, setTotalHours] = useState<number>(0)
  const [initialTotalHours, setInitialTotalHours] = useState<number | undefined>(0)
  const [totaRequestedlHours, setTotalRequestedHours] = useState<number>(0)
  const [hasChanged, setHasChanged] = useState<boolean>(false)
  const [readonly, setReadonly] = useState<boolean>(true)
  const [deleteComments, setDeleteComments] = useState<string>('')
  const [fieldsTouched, setFieldsTouched] = useState({ deleteComments: false })
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [cancelLoading, setCancelLoading] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>({})
  const [hasConflicts, setHasConflicts] = useState<boolean>(false)

  const defaultDate = '0001-01-01'

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { getRequestsData } = useContext(PageContext)

  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const isBuyOrSell = requestType.toLowerCase() === 'buy' || requestType.toLowerCase() === 'sell'

  const transformBodyEditPending = (): PendingRequestUpdateRequest | undefined => {
    if (!requestDetail) {
      return
    }
    const body = {
      requestId: requestDetail.requestId,
      days: requestDetail.requestDays.map(day => ({
        day: new Date(format(new Date(day.day), 'yyyy-MM-dd')),
        hours: Number(day.hours),
        checked: day.checked,
      })),
      conflictOverride: hasConflicts,
      comments: requestDetail.requesterComments,
      ...(requestDetail.shiftStartTime && { shiftStartTime: requestDetail.shiftStartTime }),
    }
    return body
  }

  const transformBodyChangeApproved = (): ChangeRequest | undefined => {
    if (!requestDetail) {
      return
    }
    return {
      requestId: requestDetail.requestId,
      days: requestDetail.requestDays.map(day => ({
        requestDayId: day.requestDayId,
        day: format(new Date(day.day), 'yyyy-MM-dd'),
        hours: day.hours,
        checked: day.checked,
      })),
      requesterComments: requestDetail.requesterComments,
      employeeId: requestDetail.employeeId,
      requestStatusId: 2,
      shiftStartTime: requestDetail.shiftStartTime,
      previousHoursApproved: Number(initialTotalHours?.toFixed(2)),
    }
  }

  const transformBodyCancelApproved = (): RequestCancelRequest | undefined => {
    if (!requestDetail) {
      return
    }
    return {
      requestId: requestDetail.requestId,
      cancelComments: deleteComments,
    }
  }

  const commentsMissing = () =>
    (requestType.toLowerCase() === 'day off' ||
      requestType.toLowerCase() === 'lieu' ||
      requestType.toLowerCase() === 'shift') &&
    requestDetail?.requesterComments === ''

  const handleSubmitEditPendingRequest = async () => {
    setValidationErrors({})
    const errors: any = {}

    if (commentsMissing()) {
      errors.comments = true
    }

    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors)
      return
    }

    setIsLoading(true)
    const body = transformBodyEditPending()
    if (!body) {
      return
    }
    setSubmitLoading(true)

    await availabilityService
      .updatePendingRequest(body)
      .then(async () => {
        setSubmitLoading(false)
        await availabilityService.deleteRequestLock(body.requestId)
        setIsLoading(false)
        dispatch(showSuccessMessage('Request updated'))
        getRequestsData()
        closeViewEditDrawer()
      })
      .catch(err => {
        if (err.request.status === 409) {
          dispatch(showWarningMessage('A request already exists for this user in this period'))
          setHasConflicts(true)
          setSubmitLoading(false)
        } else {
          setSubmitLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        }
      })
  }

  const handleSubmitChangeRequest = async () => {
    setIsLoading(true)
    setValidationErrors({})
    const errors: any = {}
    const body = transformBodyChangeApproved()
    if (!body) {
      return
    }

    if (body.requesterComments === '') {
      errors.comments = true
    }

    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors)
      return
    }

    if (!(Number(totalHours.toFixed(2)) > 0)) {
      dispatch(showErrorMessage('Hours is required'))
      return
    }

    setSubmitLoading(true)

    if (body.days.some(day => day.checked)) {
      await availabilityService
        .createChangeRequest(body)
        .then(async () => {
          setSubmitLoading(false)
          await availabilityService.deleteRequestLock(body.requestId)
          setIsLoading(false)
          dispatch(showSuccessMessage('Request updated'))
          getRequestsData()
          closeViewEditDrawer()
        })
        .catch(err => {
          setSubmitLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    } else {
      dispatch(
        showModalDialog({
          title: 'Cancel request?',
          message:
            'All days for this request have been unselected, which will cancel the request. Are you sure you want to proceed?',
          buttonLabel: 'Confirm',
          type: 'question',
          showModal: true,
        })
      )
      setIsLoading(false)
    }
  }

  const handleSubmitCancellationRequest = async () => {
    setFieldsTouched({ ...fieldsTouched, deleteComments: true })

    if (!deleteComments) {
      return
    }

    setIsLoading(true)
    const body = transformBodyCancelApproved()
    if (!body) {
      return
    }
    setCancelLoading(true)

    await availabilityService
      .cancelApprovedRequest(body)
      .then(async () => {
        setCancelLoading(false)
        await availabilityService.deleteRequestLock(body.requestId)
        setIsLoading(false)
        dispatch(hideModal())
        dispatch(showSuccessMessage('Cancel request has been sent'))
        getRequestsData()
        closeViewEditDrawer()
      })
      .catch(err => {
        setCancelLoading(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const handleSubmitQueryManualHolidayRequest = async () => {
    setIsLoading(true)
    const body = transformBodyChangeApproved()
    if (!body) {
      return
    }
    setSubmitLoading(true)

    await manualRequestsService
      .createQueriedRequest(body)
      .then(async () => {
        setSubmitLoading(false)
        await availabilityService.deleteRequestLock(body.requestId)
        setIsLoading(false)
        dispatch(showSuccessMessage('Holiday (M) queried successfully.'))
        getRequestsData()
        closeViewEditDrawer()
      })
      .catch(err => {
        setSubmitLoading(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (requestDetail?.statusBanner.requestStatus.toLowerCase() === 'pending') {
      handleSubmitEditPendingRequest()
    } else if (isHolidayManualRequest) {
      handleSubmitQueryManualHolidayRequest()
    } else {
      handleSubmitChangeRequest()
    }
  }

  const requestDetailHasChanged = useCallback(() => {
    if (requestDetail && initialRequestDetail) {
      return Object.keys(requestDetail).some(
        key =>
          requestDetail[key as keyof MyRequestDetailResponse]?.toString() !==
          initialRequestDetail[key as keyof MyRequestDetailResponse]?.toString()
      )
    }
    return false
  }, [initialRequestDetail, requestDetail])

  const fetchRequestDetail = useCallback(async () => {
    setIsLoading(true)
    await availabilityService
      .getMyRequestDetail(id)
      .then(data => {
        setRequestDetail(data)
        setShowRequestSummary(
          data.requestDays.length > 0 && data.requestDays.filter(day => day.checked).length > 0
        )
        setInitialTotalHours(data.totalRequestedHours)
        setInitialRequestDetail(JSON.parse(JSON.stringify(data)))
        setIsLoading(false)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsLoading(false)
        closeViewEditDrawer()
        navigate('/dashboard')
        setRequestDetail(null)
      })
  }, [dispatch, id])

  const canEdit = useCallback(
    (status: string): boolean => {
      if (
        (status?.toLowerCase() === 'approved' || status?.toLowerCase() === 'amended') &&
        (requestType?.toLowerCase() === 'holiday' ||
          requestType?.toLowerCase() === 'work from home')
      ) {
        return true
      }
      if (status?.toLowerCase() === 'pending') {
        return true
      }
      return false
    },
    [requestType]
  )

  useEffect(() => {
    if (requestDetail) {
      if (requestDetail.dateFrom && requestDetail.shiftStartTime) {
        const dateTimeString = `${format(
          new Date(requestDetail.dateFrom),
          'yyyy-MM-dd hh-mm-ss'
        ).slice(0, 11)}${requestDetail.shiftStartTime}`
        setFormattedShiftStartTime(new Date(dateTimeString))
      }
      if (requestDetail.totalRequestedHours) {
        setTotalHours(requestDetail.totalRequestedHours)
      }
      if (readonlyOverride === true) {
        setReadonly(readonlyOverride)
      } else {
        setReadonly(
          !canEdit(requestDetail.statusBanner.requestStatus) ||
            (requestDetail.lockStatus.isLocked &&
              requestDetail.lockStatus.lockedByUser?.trim().toLowerCase() !==
                userInfo?.name?.trim().toLowerCase())
        )
      }
      if (requestDetail.requestDaysCr?.length) {
        setTotalRequestedHours(
          requestDetail.requestDaysCr.reduce(
            (accumulator, currentValue) => accumulator + Number(currentValue.hours),
            0
          )
        )
      }
    }
    setHasChanged(requestDetailHasChanged())
  }, [canEdit, readonlyOverride, requestDetail, requestDetailHasChanged])

  useEffect(() => {
    fetchRequestDetail()
  }, [fetchRequestDetail])

  const isChangeRequested = () => {
    const retVal = requestDetail?.requestStatusId === RequestStatus.APPROVED_CR
    return retVal
  }

  const getRequestDayCrHours = (requestDayId: number) => {
    if (requestDetail?.requestDaysCr) {
      const requestDayCr: RequestDayRequest | undefined = requestDetail!.requestDaysCr.find(
        (x: RequestDayRequest) => x.requestDayId === requestDayId
      )
      return requestDayCr?.hours || 0
    }
  }

  const getApprovedDayHours = (requestDayId: number) => {
    const requestDay: RequestDayRequest | undefined = requestDetail!.requestDays.find(
      (approvedDay: RequestDayRequest) => approvedDay.requestDayId === requestDayId
    )
    return requestDay?.hours || 0
  }

  const checkboxesDisabled = useMemo(
    () => readonly || requestDetail?.requestStatusId !== 6,
    [readonly, requestDetail]
  )

  const { alerts } = useSelector((state: RootStore) => state.timeOff)

  function RequestDaysTextFieldHasErrors(day: RequestDayRequest) {
    if (!alerts) {
      const flag = (day.checked && (!day.hours || day.hours === '0' || day.hours > '24')) || false
      return flag
    }

    return (
      (day.checked && (!day.hours || day.hours === '0' || day.hours > '24')) ||
      alerts!.some(alert => alert.conflictDates.some(fdate => fdate, day.day))
    )
  }

  return (
    <Grid component="form" onSubmit={handleSubmit} noValidate>
      {requestDetail ? (
        <>
          <Grid container columnSpacing={6} rowSpacing={4}>
            <>
              {requestDetail.lockStatus.isLocked && (
                <Grid item xs={12} sx={{ '&.MuiGrid-item': { paddingTop: '16px' } }}>
                  <Alert
                    severity="info"
                    message={`The request is currently locked by ${requestDetail.lockStatus.lockedByUser}`}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={showRequestSummary ? 6 : 12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Paragraph weight="bold">Details</Paragraph>
                  </Grid>
                  {requestDetail.dateFrom && (
                    <Grid item xs={isBuyOrSell ? 6 : 12}>
                      <TextField
                        value={new Date(requestDetail?.dateFrom).toLocaleDateString()}
                        fullWidth
                        rows={1}
                        label={isBuyOrSell ? 'Submitted Date' : 'From'}
                        disabled
                      />
                    </Grid>
                  )}
                  {requestDetail.dateTo && absoluteDate(requestDetail.dateTo) !== defaultDate && (
                    <Grid item xs={12}>
                      <TextField
                        value={new Date(requestDetail?.dateTo).toLocaleDateString()}
                        fullWidth
                        rows={1}
                        label="To"
                        disabled
                      />
                    </Grid>
                  )}
                  {isBuyOrSell && (
                    <Grid item xs={6}>
                      <TextField
                        value={requestDetail.totalRequestedHours}
                        fullWidth
                        rows={1}
                        label="Hours Requested"
                        disabled
                      />
                    </Grid>
                  )}
                  {requestDetail.shiftStartTime && formattedShiftStartTime && (
                    <Grid item xs={12}>
                      {readonly ? (
                        <TextField
                          label="Shift Start Time"
                          fullWidth
                          value={formatTime(formattedShiftStartTime)}
                          disabled
                        />
                      ) : (
                        <TimePicker
                          value={formattedShiftStartTime}
                          label="Shift Start Time"
                          onChange={e => {
                            if (e) {
                              setRequestDetail(current => {
                                const { shiftStartTime, ...rest } =
                                  current as MyRequestDetailResponse
                                return { shiftStartTime: formatTime(e), ...rest }
                              })
                            }
                          }}
                        />
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      rows={5}
                      multiline
                      label="Comments"
                      helperText={requestType !== 'Work From Home' ? 'Required' : ''}
                      value={requestDetail.requesterComments}
                      onChange={e => {
                        setRequestDetail(current => {
                          const { requesterComments, ...rest } = current as MyRequestDetailResponse
                          return { requesterComments: e.target.value, ...rest }
                        })
                      }}
                      disabled={readonly && !isHolidayManualRequest}
                      error={validationErrors.comments}
                    />
                  </Grid>
                  {requestDetail.reviewerComments && (
                    <Grid item xs={12}>
                      <TextField
                        value={requestDetail.reviewerComments}
                        fullWidth
                        rows={5}
                        multiline
                        label="Reviewer Comments"
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
            {showRequestSummary && (
              <Grid item xs={12} lg={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Paragraph weight="bold">Request Summary</Paragraph>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      <>
                        {!isChangeRequested() &&
                          requestDetail.requestDays.map((day: RequestDayRequest) => (
                            <Grid item xs={12} key={new Date(day.day!).getTime()}>
                              <Stack direction="row" gap="2">
                                <Checkbox
                                  checked={day.hours.toString() === '0' ? false : day.checked}
                                  style={{ margin: '9px 30px 9px 0' }}
                                  disableRipple
                                  onChange={e => {
                                    const { requestDayId } = day
                                    setRequestDetail(current => {
                                      const { requestDays, ...rest } =
                                        current as MyRequestDetailResponse
                                      const dayFound =
                                        requestDayId !== 0
                                          ? requestDays.find(d => d.requestDayId === requestDayId)
                                          : requestDays.find(d => d.day === day.day)
                                      if (dayFound) {
                                        dayFound.checked = e.target.checked
                                      }
                                      const checkedDays = requestDays.filter(
                                        requestDay => requestDay.checked
                                      )
                                      const totalRequestedHours = Object.values(checkedDays).reduce(
                                        (accumulator, currentValue) =>
                                          accumulator + Number(currentValue.hours),
                                        0
                                      )
                                      rest.totalRequestedHours = totalRequestedHours
                                      setTotalHours(totalRequestedHours)
                                      return { requestDays, ...rest }
                                    })
                                  }}
                                  disabled={checkboxesDisabled}
                                />
                                <RequestDaysTextField
                                  type="number"
                                  InputProps={{ inputProps: { step: '0.1', min: 0, max: 24 } }}
                                  fullWidth
                                  label={format(new Date(day.day), 'ccc dd/MM/yyyy')}
                                  value={day.hours}
                                  bankHoliday={false} /* TODO */
                                  checked={day.checked}
                                  onChange={e => {
                                    const { requestDayId } = day
                                    setRequestDetail(current => {
                                      const { requestDays, ...rest } =
                                        current as MyRequestDetailResponse
                                      const dayFound =
                                        requestDayId !== 0
                                          ? requestDays.find(d => d.requestDayId === requestDayId)
                                          : requestDays.find(d => d.day === day.day)
                                      if (dayFound) {
                                        dayFound.hours = e.target.value
                                        dayFound.checked = Number(e.target.value) > 0
                                      }
                                      const checkedDays = requestDays.filter(
                                        requestDay => requestDay.checked
                                      )
                                      let totalRequestedHours = 0
                                      if (checkedDays.length > 0) {
                                        totalRequestedHours = Object.values(checkedDays).reduce(
                                          (accumulator, currentValue) =>
                                            accumulator + Number(currentValue.hours),
                                          0
                                        )
                                      }
                                      rest.totalRequestedHours = totalRequestedHours
                                      setTotalHours(totalRequestedHours)
                                      return { requestDays, ...rest }
                                    })
                                  }}
                                  disabled={
                                    readonly ||
                                    !initialRequestDetail?.requestDays.find(
                                      initialDay => initialDay.requestDayId === day.requestDayId
                                    )?.checked
                                  }
                                  onWheel={event =>
                                    event.target instanceof HTMLElement && event.target.blur()
                                  }
                                  error={RequestDaysTextFieldHasErrors(day)}
                                />
                              </Stack>
                            </Grid>
                          ))}
                        {isChangeRequested() && (
                          <>
                            {requestDetail.requestDays?.map((day: RequestDayRequest) => (
                              <>
                                <Grid item xs={12}>
                                  <Stack direction="row" gap={2}>
                                    <TextField
                                      type="number"
                                      label={`Approved ${format(
                                        new Date(day.day),
                                        'ccc dd/MM/yyyy'
                                      )}`}
                                      defaultValue={getApprovedDayHours(day.requestDayId)}
                                      value={getApprovedDayHours(day.requestDayId)}
                                      disabled={isChangeRequested()}
                                      fullWidth
                                      onWheel={event =>
                                        event.target instanceof HTMLElement && event.target.blur()
                                      }
                                    />
                                    {requestDetail.requestDaysCr && (
                                      <TextField
                                        type="number"
                                        label={`Requested ${format(
                                          new Date(day.day),
                                          'ccc dd/MM/yyyy'
                                        )}`}
                                        defaultValue={getRequestDayCrHours(day.requestDayId)}
                                        value={getRequestDayCrHours(day.requestDayId)}
                                        disabled={isChangeRequested()}
                                        fullWidth
                                        onWheel={event =>
                                          event.target instanceof HTMLElement && event.target.blur()
                                        }
                                      />
                                    )}
                                  </Stack>
                                </Grid>
                              </>
                            ))}
                          </>
                        )}
                      </>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <HoursContainer>
                      <HeadingTwo title={`Total ${isChangeRequested() ? 'Approved' : ''} Hours`} />
                      <HeadingTwo title={`${totalHours?.toFixed(2)} Hours`} />
                    </HoursContainer>
                    {isChangeRequested() && !isCancellation && (
                      <HoursContainer>
                        <HeadingTwo title="Total Requested Hours" />
                        <HeadingTwo title={`${totaRequestedlHours?.toFixed(2)} Hours`} />
                      </HoursContainer>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <DrawerFooter>
            <>
              <Button
                color="secondary"
                label="Close"
                onClick={e => {
                  closeViewEditDrawer()
                }}
                loading={closeLoading}
              />
              <Button label="Submit" type="submit" disabled={!hasChanged} loading={submitLoading} />
            </>
          </DrawerFooter>
        </>
      ) : (
        <LoadingIndicator show={isLoading} />
      )}
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
          setFieldsTouched({ ...fieldsTouched, deleteComments: false })
        }}
        onClick={handleSubmitCancellationRequest}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
        buttonLoading={cancelLoading}
        dropdown={
          <Grid container>
            <Grid item xs={12} md={12} spacing={4} mt={4}>
              <Grid mt={2}>
                <TextField
                  label="Comments"
                  fullWidth
                  multiline
                  rows={4}
                  onChange={e => {
                    setDeleteComments(e.target.value)
                  }}
                  onBlur={() => setFieldsTouched({ ...fieldsTouched, deleteComments: true })}
                  error={fieldsTouched.deleteComments && deleteComments === ''}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}

export default ViewEditOccurrence
