import { GridColDef, GridRowClassNameParams, GridRowId, GridRowParams } from '@mui/x-data-grid'
import { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Box,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Stack,
  useMediaQuery,
} from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import { useSelector } from 'react-redux'
import { DateRange } from '@mui/x-date-pickers-pro'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro'
import { useNavigate } from 'react-router-dom'
import { setSelectedDepartment, setSelectedTeam } from '../../redux/reducers/appSettingsReducer'
import {
  setSelectedDateRangeFilter,
  setSelectedDepartmentFilter,
  setSelectedStatusFilter,
  setSelectedTeamFilter,
  setSelectedTeamsFilter,
} from '../../redux/reducers/myActionsReducer'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import {
  setAbsenceArray,
  setDirectReportsToggle,
  setFilteredAbsences,
  setTabSelected,
} from '../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { DepartmentValuesResponse, SelectOption, Team } from '../../services/dashboardService'
import { manualRequestsService } from '../../services/myActionsService'
import AutocompleteList from '../../shared/UI/AutocompleteList'
import DateRangePicker from '../../shared/UI/DateRangePicker'
import DropdownMenu from '../../shared/UI/DropdownMenu'
import { IOSStyleSwitch } from '../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import NoResult from '../../shared/UI/NoResult'
import Paragraph from '../../shared/UI/Paragraph'
import RefreshButton from '../../shared/UI/RefreshButton'
import StatusLabel from '../../shared/UI/StatusLabel'
import TypeLabel from '../../shared/UI/TypeLabel'
import Card from '../../shared/layout/Card'
import { isMobileDown } from '../../theme/deviceChecks'
import { getPalleteTypeByProp } from '../../theme/palette'
import theme from '../../theme/theme'
import { BaseResponse } from '../../types/base-response'
import { OptionValue } from '../../types/option-value'
import { getSelectValuesByType, getFilteredDepartmentsDropdownOptions } from '../../utils/app-utils'
import { getLocalDateString } from '../../utils/date-utils'
import UserErrorMessage from '../../utils/errorFilter'
import Alert from '../../shared/UI/Alert/Alert'
import { CallOutSummary } from '../CallOutSummary/CallOutSummary'
import { RowExpansionToggle } from './RowExpansionToggle'
import { enhancementsContent } from '../../utils/constants'
import { v2MyActionsService } from '../../services/myActionsServiceV2'
import { ManagerRequests } from '../../types/manager-requests'
import { SytledMyActionsGrid } from './StyledMyActionsGrid'
import useAbortController from '../../utils/Hooks/useAbortController'

const unallocated = 'unallocated'
const myActions = 'myactions'

export type MyActionsGridPropsLocal = {
  showFilter: boolean
}

function NoRowsOverlay() {
  return <NoResult message="No Actions" showImage />
}
function LoadingOverlay() {
  return <LoadingIndicator show alignItems="center" />
}

export function MyActionsGrid({ showFilter }: MyActionsGridPropsLocal) {
  const apiRef = useGridApiRef()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState<number>(10)
  const [requestStatusId, setRequestStatusId] = useState<number>(97) // Awaiting Action
  const [requestStatuses, setRequestStatuses] = useState<SelectOption[]>([])
  const [showMobileFilter, setShowMobileFilter] = useState<boolean>(false)
  const [rerenderCheckBox, setRerenderCheckBox] = useState<number>(1)
  const [rerenderDataGrid, setRerender] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [doesDisplayInfoMessage, setDoesDisplayInfoMessage] = useState<boolean>(true)
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])
  const mobile = useMediaQuery(isMobileDown())

  const { abortControllerRef, abortAndReplaceController } = useAbortController()

  const { directReportsToggle, absenceArray } = useSelector((state: RootStore) => state.timeOff)

  let { filteredAbsences } = useSelector((state: RootStore) => state.timeOff)

  const { departments, allEmployees, selectedDepartment, allTeams } = useSelector(
    (state: RootStore) => state.appSettings
  )

  const dropdownDepartments = useMemo(
    () => getFilteredDepartmentsDropdownOptions(departments, allEmployees),
    [allEmployees, departments]
  )

  const {
    selectedDepartmentFilter,
    selectedTeamsFilter,
    selectedTeamFilter,
    dateRangeFilter,
    statusFilter,
  } = useSelector((state: RootStore) => state.myActions)

  const departmentValuesResponse = useSelector<RootStore, DepartmentValuesResponse | undefined>(
    (state: RootStore) => state.appSettings.departmentValuesResponse
  )

  const userDepartment = useSelector<RootStore, SelectOption | null>(
    (state: RootStore) => state.appSettings.loggedInEmployeeDepartment
  )

  const { enhancement } = useSelector((state: RootStore) => state.featureToggles)
  const getEmployees = () => {
    const records = absenceArray
    if (mobile) {
      filteredAbsences = records.filter(t => t.requestType === 'Holiday')
    }
    return filteredAbsences || records
  }

  const showLoadingSpinner = Boolean(isLoading && !getEmployees().length)

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const { calendarDetailsResponse } = useSelector((state: RootStore) => state.appSettings)

  const startDate = currentEntitlementPeriodResponse?.startDate
  const endDate = currentEntitlementPeriodResponse?.endDate

  const requestStatusResult = getSelectValuesByType('RequestStatus')

  const shouldSelectAllOption = useCallback((): boolean =>
      userDepartment?.displayValue.toLowerCase().trim() === unallocated || departments.length > 1,
    [userDepartment?.displayValue, departments.length]
  )

  if (!requestStatuses.length && requestStatusResult.length) {
    if (requestStatusResult.some((status: OptionValue) => status.value === 0)) {
      // This is to check API's RequestValues>RequestStatus already has an item { value: 0, displayValue: 'All' }
      // If so, that's all that's needed
      setRequestStatuses(requestStatusResult)
    } else {
      // Otherwise, we need to add 0/All programatically
      setRequestStatuses([{ value: 0, displayValue: 'All' }, ...requestStatusResult])
    }
  }

  const fetchRequests = useCallback(async () => {
    if (!startDate || !endDate || !currentEntitlementPeriodResponse || !calendarDetailsResponse) {
      return
    }

    abortAndReplaceController()

    const startDateRequest = new Date(
      new Date(currentEntitlementPeriodResponse.startDate).getFullYear(),
      new Date(currentEntitlementPeriodResponse.startDate).getMonth(),
      new Date(currentEntitlementPeriodResponse.startDate).getDate()
    )

    const endDateRequest = new Date(
      new Date(
        calendarDetailsResponse.calendarDetails[
          calendarDetailsResponse.calendarDetails.length - 1
        ].endDate
      ).getFullYear(),
      new Date(
        calendarDetailsResponse.calendarDetails[
          calendarDetailsResponse.calendarDetails.length - 1
        ].endDate
      ).getMonth(),
      new Date(
        calendarDetailsResponse.calendarDetails[
          calendarDetailsResponse.calendarDetails.length - 1
        ].endDate
      ).getDate()
    )
    const getRequests: Promise<ManagerRequests> = enhancement
      ? v2MyActionsService.getRequests(
          {
            directReportees: directReportsToggle,
            dateFrom: dateRangeFilter[0] || startDateRequest,
            dateTo: dateRangeFilter[1] || endDateRequest,
            departmentId: selectedDepartmentFilter?.value,
            departmentTeamId: selectedTeamFilter?.teamId,
            requestStatusId: statusFilter,
          },
          abortControllerRef.current
        )
      : manualRequestsService.getRequests(
          directReportsToggle,
          dateRangeFilter[0] || startDateRequest,
          dateRangeFilter[1] || endDateRequest,
          selectedDepartmentFilter?.value,
          selectedTeamFilter?.teamId,
          statusFilter,
          abortControllerRef.current
        )
    await getRequests
      .then(deptTeams => {
        dispatch(setAbsenceArray(deptTeams.requests))
      })
      .catch(err => {
        if (!err?.response?.data) {
          return
        }
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    abortAndReplaceController,
    calendarDetailsResponse,
    currentEntitlementPeriodResponse,
    dateRangeFilter,
    directReportsToggle,
    dispatch,
    endDate,
    enhancement,
    selectedDepartmentFilter?.value,
    selectedTeamFilter?.teamId,
    startDate,
    statusFilter,
    abortControllerRef.current
  ])

  useEffect(() => {
    fetchRequests()
  }, [
    selectedTeamFilter,
    directReportsToggle,
    dispatch,
    dateRangeFilter,
    statusFilter,
    startDate,
    endDate,
    currentEntitlementPeriodResponse,
    selectedDepartmentFilter?.value,
    calendarDetailsResponse,
  ])
  
  const allOption = useMemo(() =>
    dropdownDepartments.find(dep => dep.value === 0),
    [dropdownDepartments]
  )

  const hasExplicitDepartmentSelection = useCallback(() =>
    (selectedDepartmentFilter && allOption) &&
      (selectedDepartmentFilter.value !== allOption.value),
    [selectedDepartmentFilter, allOption]
  )

  useEffect(() => {
    if (hasExplicitDepartmentSelection()) {
      return
    }
    
    const toBeSelected = (shouldSelectAllOption()
      ? allOption
      : userDepartment
    ) ?? null

    dispatch(setSelectedDepartmentFilter(toBeSelected))
    dispatch(setSelectedDepartment(toBeSelected))
  }, [dispatch, userDepartment, allOption])

  const refreshFields = () => {
    if (!userDepartment) {
      return
    }
    
    const toBeSelected = shouldSelectAllOption()
      ? allOption ?? null
      : userDepartment
    
    dispatch(setSelectedDepartment(toBeSelected))
    dispatch(setSelectedDepartmentFilter(toBeSelected))
    dispatch(setDirectReportsToggle(true))
    dispatch(setFilteredAbsences(undefined))
    dispatch(setSelectedTeam(null))
    dispatch(setTabSelected('All'))
    dispatch(setSelectedDateRangeFilter([null, null]))
    dispatch(setSelectedStatusFilter(requestStatusId || 97)) // Awaiting Action
  }

  useEffect(() => {
    function resetFilterWhenNavigatingAwayFromMyAvailability() {
      if (window.location.href.toLowerCase().includes(myActions)) {
        return
      }

      refreshFields()
      dispatch(setAbsenceArray([]))
    }

    return resetFilterWhenNavigatingAwayFromMyAvailability
  }, [])

  const calculateStatusLabel = (cellValues: any) => {
    if (!cellValues.statusDescription) return

    if (cellValues.isQueried) {
      return 'Queried'
    }
    return cellValues.statusDescription
  }

  const handleNavigation = (type: string, id: number) => {
    // TODO this switch statement needs to be replaced with something else as it is affecting the routing - Mike B
    // TODO re above ^^^, I have updated the ProtectedRoute function to only allow the base route
    //  e.g. /myactions/holidayrequest/1234 becomes /myactions
    //  However, I need to touch base with Mike or Dan to ensure my assumption about this is being done in error is correct.

    let targetUrl = ''
    switch (type.toLocaleLowerCase()) {
      case 'buy':
        targetUrl = `buyrequest`
        break
      case 'sell':
        targetUrl = `sellrequest`
        break
      case 'absence':
        targetUrl = `absenceoccurrence`
        break
      case 'late':
        targetUrl = `lateoccurrence`
        break
      case 'holiday':
        targetUrl = `holidayrequest`
        break
      case 'holiday (m)':
        targetUrl = `manualholidayrequest`
        break
      case 'work from home':
        targetUrl = `workfromhomerequest`
        break
      case 'lieu':
        targetUrl = `lieurequest`
        break
      case 'lieu (m)':
        targetUrl = `manuallieurequest`
        break
      case 'day off':
        targetUrl = `dayoffrequest`
        break
      case 'shift':
        targetUrl = `shiftrequest`
        break
      case 'birthday':
        targetUrl = `birthdayrequest`
        break
      case 'course':
        targetUrl = `courserequest`
        break
      case 'adjustment':
        targetUrl = `adjustmentrequest`
        break
      case 'paternity':
        targetUrl = `paternityrequest`
        break
      case 'maternity':
        targetUrl = `maternityrequest`
        break
      case 'night shift cover':
        targetUrl = `nightshiftcoverrequest`
        break
      case 'twilight shift cover':
        targetUrl = `twilightshiftcoverrequest`
        break
      case 'other':
        targetUrl = `otherrequest`
        break
      default:
        break
    }
    const baseUrl = 'myactions'
    navigate(`/${baseUrl}/${targetUrl}/${id}`, { state: { previousLocation: `/${baseUrl}` } })
  }

  const getColumnTestId = (columnName: string): string => `MyActions-Chart-${columnName}`
  const handleOnDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds)
  }, [])
  const getRowClassName = (params: GridRowClassNameParams) =>
    detailPanelExpandedRowIds.includes(params.id) ? 'expanded' : ''

  /**
   * Append panel if requestType is call out
   */
  const getCalloutSummaryPanel = useCallback(({ row }: GridRowParams) => {
    if (row.requestType === enhancementsContent.callOut) {
      return <CallOutSummary enhancementId={row.id} />
    }
    return null
  }, [])

  /**
   * Desktop View Columns
   */
  const columns: GridColDef[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,

      renderCell: params => <RowExpansionToggle id={params.id} value={params.value} />,
    },
    {
      field: 'displayName',
      headerName: 'Name',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),

      renderCell: params => (
        <Link component="button" variant="body2">
          {params.row.displayName}
        </Link>
      ),
    },

    {
      field: 'requestType',
      headerName: 'Type',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),

      renderCell: cellValues => (
        <TypeLabel
          label={getPalleteTypeByProp(cellValues.row.requestType).label}
          type={cellValues.row.requestType}
          testId={`type-chip-${cellValues.row.id}`}
        />
      ),
    },
    {
      field: 'submitDateTime',
      headerName: 'Submitted',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),

      renderCell: cellValues => getLocalDateString(cellValues.value),
    },
    {
      field: 'dateFrom',
      headerName: 'Date from',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),

      renderCell: cellValues => getLocalDateString(cellValues.value),
    },
    {
      field: 'dateTo',
      headerName: 'Date to',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),

      renderCell: cellValues => cellValues.value && getLocalDateString(cellValues.value),
    },
    {
      field: 'statusDescription',
      headerName: 'Status',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),

      renderCell: cellValues => (
        <StatusLabel
          status={
            cellValues.row.isCancellation
              ? 'Cancellation Requested'
              : calculateStatusLabel(cellValues.row)
          }
        />
      ),
    },
  ]

  /**
   * Mobile View Columns
   */
  const mobileColumns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Name',
      headerClassName: 'header',
      width: 160,
      minWidth: 160,
      disableColumnMenu: true,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => handleNavigation(params.row.requestType, params.row.id)}
        >
          {params.row.displayName}
        </Link>
      ),
    },

    {
      field: 'requestType',
      headerName: 'Type',
      headerClassName: 'header',
      width: 130,
      minWidth: 130,
      disableColumnMenu: true,
      renderCell: cellValues => (
        <TypeLabel label={cellValues.row.requestType} type={cellValues.row.requestType} />
      ),
    },
    {
      field: 'submitDateTime',
      headerName: 'Submitted',
      headerClassName: 'header',
      width: 130,
      minWidth: 130,
      disableColumnMenu: true,
      type: 'date',
      renderCell: cellValues => getLocalDateString(cellValues.value),
    },
    {
      field: 'dateFrom',
      headerName: 'Date from',
      headerClassName: 'header',
      width: 130,
      minWidth: 130,
      disableColumnMenu: true,
      type: 'date',
      renderCell: cellValues => getLocalDateString(cellValues.value),
    },
    {
      field: 'dateTo',
      headerName: 'Date to',
      headerClassName: 'header',
      width: 130,
      minWidth: 130,
      disableColumnMenu: true,
      type: 'date',
      renderCell: cellValues => cellValues.value && getLocalDateString(cellValues.value),
    },
    {
      field: 'statusDescription',
      headerName: 'Status',
      headerClassName: 'header',
      width: 180,
      minWidth: 180,
      disableColumnMenu: true,
      renderCell: cellValues => (
        <StatusLabel
          status={
            cellValues.row.isCancellation
              ? 'Cancellation Requested'
              : cellValues.row.statusDescription
          }
        />
      ),
    },
  ]

  const handleDepartmentChange = (event: any, department: SelectOption | null) => {
    dispatch(setSelectedDepartment(department))
    dispatch(setSelectedDepartmentFilter(department))
    dispatch(setSelectedTeamFilter(null))
  }

  useEffect(() => {
    if (!departments) {
      return
    }

    const teams =
      selectedDepartment?.value !== 0
        ? allTeams.filter(team => team.departmentId === selectedDepartmentFilter?.value)
        : []

    dispatch(setSelectedTeamsFilter(teams))
  }, [
    dispatch,
    departments,
    departmentValuesResponse,
    selectedDepartmentFilter,
    allTeams,
    selectedDepartment?.value,
  ])

  const handleTeamChange = (event: any, team: Team | null) => {
    dispatch(setSelectedTeamFilter(team))
  }

  const handleMobileFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowMobileFilter(!showMobileFilter)
  }

  const isVisible = () =>
    selectedDepartmentFilter?.value !== userDepartment?.value ||
    selectedTeamFilter ||
    dateRangeFilter[0] ||
    statusFilter !== 6 ||
    !directReportsToggle

  const isManager = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  ).includes('ViewWhoAwayManager')
  
  const hasOneOrLessDepartmentOptions = useCallback(
    () => dropdownDepartments.length <= 1,
    [dropdownDepartments.length]
  )

  const selectedAllDepartmentsOptionOrNoSelection = useCallback(
    () => !selectedDepartment?.value,
    [selectedDepartment?.value]
  )


  return (
    <Card>
      {mobile ? (
        <Grid container data-testid="my-actions-mobile-view-message">
          <Grid item xs={12} mb={1}>
            <Grid item mb={2}>
              {isManager && doesDisplayInfoMessage && (
                <Alert
                  onClose={() => {
                    setDoesDisplayInfoMessage(false)
                  }}
                  severity="info"
                  message="Only Holiday requests can be viewed and actioned on mobile devices"
                />
              )}
            </Grid>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box display="flex" justifyContent="flex-start" width="33.3%">
                <Paragraph>
                  <b>Results:&nbsp;</b>
                  {getEmployees().length}
                </Paragraph>
              </Box>
              <Box display="flex" justifyContent="center" width="33.3%">
                {isVisible() && (
                  <Link component="button" variant="body2" onClick={refreshFields}>
                    Clear Filters
                  </Link>
                )}
              </Box>
              <Box display="flex" justifyContent="flex-end" width="33.3%">
                <IconButton onClick={handleMobileFilterClick}>
                  <TuneIcon
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </IconButton>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Collapse orientation="vertical" in={showFilter || showMobileFilter}>
        <Grid container display="flex" spacing={4} data-testid="my-actions-filter">
          <Grid item xs={12}>
            <Grid container spacing={3} mb={3} sx={{ paddingTop: '6px' }}>
              <Grid item xs={12} md={2}>
                <AutocompleteList
                  id="department"
                  label="Department"
                  data={dropdownDepartments}
                  textField="displayValue"
                  value={selectedDepartment || selectedDepartmentFilter}
                  onChange={handleDepartmentChange}
                  dataTestId="MyActions-Filters-DepartmentDDL"
                  disabled={hasOneOrLessDepartmentOptions()}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <AutocompleteList
                  id="teams"
                  label="Teams"
                  data={selectedTeamsFilter}
                  textField="teamName"
                  value={selectedTeamFilter}
                  onChange={handleTeamChange}
                  dataTestId="MyActions-Filters-TeamsDDL"
                  disabled={selectedAllDepartmentsOptionOrNoSelection()}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DateRangePicker
                  arrange={mobile ? 'Stack' : 'Horizontal'}
                  onChange={(date: DateRange<Date>) => dispatch(setSelectedDateRangeFilter(date))}
                  value={dateRangeFilter}
                  dataTestId="MyActions-Filters-"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <DropdownMenu
                  label="Status"
                  id="searchStatus"
                  data={requestStatuses}
                  textField="displayValue"
                  valueField="value"
                  value={statusFilter}
                  onChange={e => dispatch(setSelectedStatusFilter(Number(e.target.value)))}
                  dataTestId="MyActions-Filters-StatusDDL"
                />
              </Grid>
              {mobile && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<IOSStyleSwitch checked={directReportsToggle} />}
                    label="Direct Reports"
                    labelPlacement="start"
                    onClick={() => dispatch(setDirectReportsToggle(!directReportsToggle))}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: 'rgb(116, 116, 116) !important;',
                      margin: 0,
                    }}
                  />
                </Grid>
              )}
              {!mobile && (
                <Grid item xs={2} display="flex" alignItems="center" justifyContent="flex-end">
                  <RefreshButton onClick={refreshFields} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
      <SytledMyActionsGrid
        key={rerenderDataGrid}
        apiRef={apiRef}
        getRowId={row => row.id}
        rows={getEmployees()}
        columns={mobile ? mobileColumns : columns}
        disableSelectionOnClick
        pagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        rowHeight={50}
        autoHeight
        loading={showLoadingSpinner}
        components={{
          NoRowsOverlay,
          LoadingOverlay,
        }}
        pinnedColumns={{ left: mobile ? ['displayName'] : [] }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'submitDateTime', sort: 'asc' }],
          },
        }}
        onRowClick={params => {
          handleNavigation(params.row.requestType, params.row.id)
        }}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleOnDetailPanelExpandedRowIdsChange}
        getDetailPanelContent={getCalloutSummaryPanel}
        getDetailPanelHeight={() => 105}
        getRowClassName={getRowClassName}
        componentsProps={{
          panel: {
            sx: {
              '& .MuiNativeSelect-select option': {
                font: '13px "Poppins", sans-serif',
              },
            },
          },
        }}
        data-testid={getColumnTestId}
      />
    </Card>
  )
}
