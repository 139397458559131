import { DateRange } from '@mui/x-date-pickers-pro'
import { addDays, format } from 'date-fns'
import { RequestType } from '../../models'
import { CalloutDetails, EnhancementPostBody } from '../../models/enhancement'
import { AbsenceDayRequest } from '../../types/absence'

import {
  formatDateWithTimeZone,
  formatDateWithTimeZoneStr,
  getDurationInFractionalHours,
} from '../date-utils'
import { ConflictMatch } from '../../types/base-response'
import { tabType } from '../../components/Absence/types'
import { DateFormats } from '../../api/absence/tempTypes/generalprops'

export interface BuilderArgs {
  requestType: RequestType
  employeeId: number
  dateRange: DateRange<Date>
  comments: string
  selectedDays: AbsenceDayRequest[] | null
  financialYear: string
  increase: boolean
  hours: number
  overrideConflicts: boolean
  alerts: ConflictMatch[] | null
  conflicts: boolean
  calloutDetails?: CalloutDetails
  project?: string
  selectedDate?: Date | null
}

export const convertTabTypeForRequest = (tabToConvert: string) => {
  if (tabToConvert === 'Holiday (M)') return 'ForcedHoliday'
  if (tabToConvert === 'Lieu Day (M)') return 'LieuDay'
  if (tabToConvert === 'Work From Home') return 'WorkFromHome'
  if (tabToConvert === 'Maternity') return 'MaternityLeave'
  if (tabToConvert === 'Paternity') return 'PaternityLeave'
  if (tabToConvert === 'Night Shift Cover') return 'NightShiftCover'
  if (tabToConvert === 'Twilight Shift Cover') return 'TwilightShiftCover'
  if (tabToConvert === 'Overtime (M)') return 'Overtime'
  if (tabToConvert === 'Night (M)') return 'Night'
  if (tabToConvert === 'On Call (M)') return 'On Call'
  if (tabToConvert === 'Call Out (M)') return 'Call Out'
  return tabToConvert
}

export const buildEnhancementPostBody = (args: BuilderArgs): EnhancementPostBody => {
  const body: EnhancementPostBody = {
    employeeId: args.employeeId,
    enhancementType: convertTabTypeForRequest(tabType(args.requestType)),
    dateRange: {
      start: format(args.dateRange[0]!, DateFormats.DATE_AND_TIME),
      end: format(args.dateRange[1]!, DateFormats.DATE_AND_TIME),
    },
    comments: args.comments,
    days:
      args.selectedDays &&
      args.selectedDays?.map(day => ({
        day: formatDateWithTimeZoneStr(day.day, 'yyyy-MM-dd'),
        hours: day.hours,
        checked: day.checked,
      })),
  }
  if (args.requestType === RequestType.CALL_OUT && args.calloutDetails)
    body.calloutDetails = args.calloutDetails

  if (args.project) body.project = args.project

  return body
}

export const calculateCalloutDays = (dateRange: DateRange<Date>) => {
  const [start, end] = dateRange
  const allDays: AbsenceDayRequest[] = []
  if (!start || !end) return allDays
  const endOfTheDay1 = new Date(addDays(start, 1).setHours(0, 0, 0, 0))
  const remainingHoursOfThaDay1 = getDurationInFractionalHours(start, endOfTheDay1)
  const totalDuration = getDurationInFractionalHours(start, end)
  if (totalDuration <= remainingHoursOfThaDay1) {
    allDays.push({
      day: formatDateWithTimeZone(start),
      hours: totalDuration,
      checked: true,
    })
  } else {
    allDays.push({
      day: formatDateWithTimeZone(start),
      hours: remainingHoursOfThaDay1,
      checked: true,
    })
    allDays.push({
      day: formatDateWithTimeZone(end),
      hours: totalDuration - remainingHoursOfThaDay1,
      checked: true,
    })
  }
  return allDays
}
