import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { RootStore } from '../../../redux/store'

const validRoutes = [
  '/dashboard',
  '/myavailability',
  '/myextras',
  '/wallchart',
  '/booking',
  '/myactions',
  '/absenceandlate',
  '/holiday',
  '/settings',
]

const useBackButton = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const isValidLocation = useCallback((route: string): boolean => validRoutes.includes(route), [])
  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const isManager = useMemo(() => userPermissions.includes('ViewMyActions'), [userPermissions])

  const isExternalNavigation = useMemo(() => window.history.length === 1, [])

  const handleNavigation = useCallback(() => {
    if (isExternalNavigation) {
      navigate(isManager ? '/myactions' : '/myavailability')
      return
    }

    if (window.history.length > 1) {
      const navigateTo =
        location.state && isValidLocation(location.state?.previousLocation as string)
          ? (location.state.previousLocation as string)
          : activeRoute
      navigate(navigateTo, { state: location.state })
      return
    }
    navigate('/myavailability')
  }, [isExternalNavigation, navigate, isManager, location.state, isValidLocation, activeRoute])

  return { handleNavigation }
}

export default useBackButton
