import {
  DateTimePicker as MuiDateTimePicker,
  PickersDay,
  PickersDayProps,
  MobileDateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React, { useEffect, useState } from 'react'
import { TextField, useMediaQuery } from '@mui/material'
import locale from 'date-fns/locale/en-GB'
import theme from '../../../theme/theme'

interface DateTimePickerProps {
  label: string
  value: Date | null
  onChange: (selectedDate: Date | null, keyboardInputValue?: string) => void
  error?: boolean
  required?: boolean
  helperText?: string
  isHTL?: boolean
  maxDate?: Date | undefined
  disabledDates?: string[]
  inputFormat?: string
  customOnClose?: (fullDate: Date) => void | undefined
  dataTestId?: string
}

function DateTimePicker({
  label,
  value,
  onChange,
  error,
  required,
  helperText,
  maxDate,
  disabledDates = [],
  inputFormat = 'dd/MM/yyyy HH:mm',
  customOnClose,
  dataTestId,
}: DateTimePickerProps) {
  const [currentDateTime, setCurrentDateTime] = useState(value)
  const [open, setOpen] = React.useState(false)
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const disableDate = (date: Date) =>
    disabledDates
      ? disabledDates.some(
          disabledDate => date.toISOString() === new Date(disabledDate).toISOString()
        )
      : false

  const onChangeHandle = (selectedDate: Date | null, keyboardInputValue?: string) => {
    setCurrentDateTime(selectedDate)

    onChange(selectedDate, keyboardInputValue)
  }

  useEffect(() => {
    if (open) return

    if (!customOnClose) return

    if (!currentDateTime) return

    customOnClose(currentDateTime)
  }, [open, currentDateTime, customOnClose])

  const renderDisabledDay = (
    date: Date,
    selectedDays: Date[],
    dateRangePickerDayProps: PickersDayProps<Date>
  ) => {
    const defaultSx = {
      '&.MuiPickersDay-today.MuiButtonBase-root:not(.Mui-selected)': {
        border: '1px solid rgba(32,197,160,0.3)',
        backgroundColor: 'rgba(32,197,160,0.3)',
      },
    }
    const asDisabled = {
      '&.MuiPickersDay-root': {
        color: 'rgba(0,0,0,0.2)',
      },
    }

    return (
      <PickersDay
        {...dateRangePickerDayProps}
        day={date}
        sx={disableDate(date) ? { ...asDisabled, ...defaultSx } : defaultSx}
      />
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      {mobile ? (
        <MobileDateTimePicker
          label={label}
          inputFormat="dd/MM/yyyy HH:mm"
          value={value}
          onChange={onChangeHandle}
          maxDate={maxDate}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              required={required}
              error={error}
              autoComplete="off"
              onClick={() => setOpen(true)}
              helperText={helperText}
              data-testid={dataTestId}
            />
          )}
          disableOpenPicker
          shouldDisableDate={disableDate}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
        />
      ) : (
        <MuiDateTimePicker
          label={label}
          inputFormat={inputFormat}
          value={value}
          onChange={onChangeHandle}
          renderDay={renderDisabledDay}
          maxDate={maxDate}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              required={required}
              error={error}
              autoComplete="off"
              onClick={() => setOpen(true)}
              helperText={helperText}
              data-testid={dataTestId}
            />
          )}
          disableOpenPicker
          shouldDisableDate={disableDate}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false)
          }}
          open={open}
        />
      )}
    </LocalizationProvider>
  )
}

export default DateTimePicker
