import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ButtonSmallProps } from './types'

export const SmallButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'minWidth',
})<ButtonSmallProps>(({ minWidth }) => ({
  ...{
    display: 'flex',
    alignItems: 'center',
    padding: '0px 15px',
    minWidth: minWidth || '140px',
    margin: '13px 0',
  },
})) as React.ComponentType<ButtonSmallProps>
