import { ChevronLeft } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import theme from '../../../theme/theme'
import useBackButton from './useBackButton'

function BackButtonDesktop() {
  const { handleNavigation } = useBackButton()
  return (
    <IconButton
      onClick={() => {
        handleNavigation()
      }}
      sx={{ position: 'absolute', left: 10, marginTop: 0.4 }}
    >
      <ChevronLeft
        sx={{
          color: theme.palette.primary.main,
        }}
      />
    </IconButton>
  )
}

export default BackButtonDesktop
