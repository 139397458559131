import React from 'react'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import styled from 'styled-components'
import format from 'date-fns/format'
import { getComponentStyles, contentStyles } from './PayPeriodBannerStyles'
import { StatusChip } from '../../../shared/UI/StatusChip/StatusChip'
import { PayPeriodContent } from '../../../shared/UI/PayPeriodContent/PayPeriodContent'

export const componentTestid = 'pay-period-banner'
export interface PayPeriodBannerProps {
  status: 'submitted' | 'pending'
  statusDate: Date
  dateRange: {
    start: string
    end: string
  }
  currentPayPeriod: number
  disableNext: boolean
  disablePrevious: boolean
  onNext: () => void
  onPrevious: () => void
}

export function PayPeriodBanner({
  status,
  statusDate,
  dateRange,
  currentPayPeriod,
  disableNext,
  disablePrevious,
  onNext,
  onPrevious,
}: PayPeriodBannerProps) {
  const { bannerStyles, disabledChevronStyles, enabledChevronStyles, textColor, bgColor } =
    getComponentStyles(status)

  const getStatuLabel = () =>
    `${status === 'submitted' && statusDate ? 'Submitted on' : 'Submit by'}: ${format(
      new Date(statusDate),
      'do MMMM'
    )}`
  return (
    <Box sx={bannerStyles} data-testid={componentTestid}>
      <IconButton
        data-testid={`${componentTestid}-previous`}
        size="small"
        disabled={disablePrevious}
        onClick={() => onPrevious()}
      >
        <ChevronLeftIcon sx={disablePrevious ? disabledChevronStyles : enabledChevronStyles} />
      </IconButton>
      <Box sx={contentStyles}>
        <Typography
          align="center"
          sx={{ color: textColor }}
          data-testid={`${componentTestid}-range`}
        >
          <PayPeriodContent dateRange={dateRange} payPeriod={currentPayPeriod} />
        </Typography>
        <span
          style={{ position: 'absolute', right: '36px' }}
          data-testid={`${componentTestid}-status`}
        >
          <StatusChip type={status} label={getStatuLabel()} />
        </span>
      </Box>
      <IconButton
        data-testid={`${componentTestid}-next`}
        size="small"
        disabled={disableNext}
        onClick={() => onNext()}
      >
        <ChevronRightIcon sx={disableNext ? disabledChevronStyles : enabledChevronStyles} />
      </IconButton>
    </Box>
  )
}
