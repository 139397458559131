import { AvailabilitySummaryType } from '../types/availability-summary-type'
import { AvailabilityType } from '../types/availability-type'
import { CancelHolidayRequest } from '../types/cancel-holiday-request-response'
import { ChangeRequest } from '../types/change-request'
import { MyRequests } from '../types/my-requests'
import { PendingRequestUpdateRequest } from '../types/pending-request-update-request'
import { RequestCancelRequest } from '../types/request-cancel-request'
import { MyRequestDetailResponse } from '../types/requests'
import { httpServiceV1 } from './httpServiceV1'
import { RequestCancelChangeRequest } from '../types/request-cancel-cancellation-request'
import { LateDetailsResponse } from '../types/late-details-response'
import { AbsenceDetailsResponse } from '../types/absence'
import { BaseResponse } from '../types/base-response'
import { MyEntitlementResponse } from '../types/my-entitlement-response'

const requestsBaseURL = 'Availability'

const availabilityService = {
  getAvailability: async (year: number): Promise<AvailabilityType> =>
    httpServiceV1.get(`${requestsBaseURL}/Get?year=${year}`),

  getSummary: async (year: number): Promise<AvailabilitySummaryType> =>
    httpServiceV1.get(`${requestsBaseURL}/Summary?year=${year}`),

  getMyAvailability: async (year: number): Promise<MyRequests> =>
    httpServiceV1.get(`${requestsBaseURL}/MyAvailability?year=${year}`),

  updatePendingRequest: async (body: PendingRequestUpdateRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/PendingRequest`, body),

  createChangeRequest: async (body: ChangeRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/ChangeRequest`, body),

  getMyRequestDetail: async (requestId: number): Promise<MyRequestDetailResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/MyRequestDetail?RequestId=${requestId}`),

  getMyLateDetail: async (requestId: number): Promise<LateDetailsResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/MyLateDetail?LateId=${requestId}`),

  getMyAbsenceDetail: async (requestId: number): Promise<AbsenceDetailsResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/MyAbsenceDetail?AbsenceId=${requestId}`),

  deleteMyRequest: async (requestId: number): Promise<void> =>
    httpServiceV1.del(`${requestsBaseURL}/PendingRequest?requestId=${requestId}`),

  cancelApprovedRequest: async (body: RequestCancelRequest): Promise<CancelHolidayRequest> =>
    httpServiceV1.put(`${requestsBaseURL}/Cancel`, body),

  cancelChangeRequest: async (body: RequestCancelChangeRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/Cancel/ChangeRequest`, body),

  deleteRequestLock: async (requestId: number): Promise<BaseResponse> =>
    httpServiceV1.del(`${requestsBaseURL}/LockRequest?RequestId=${requestId}`),

  getEntitlement: async (year: number): Promise<MyEntitlementResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/Entitlement?Year=${year}`),
}

export { availabilityService }
