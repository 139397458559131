import { format } from 'date-fns'
import { EnhancementRequestParams } from '../models/enhancement/enhancement-request-params'
import { httpServiceV2 } from './httpServiceV2'
import { ManagerRequests } from '../types/manager-requests'
import {
  BulkSubmitRequest,
  BulkSubmitResponse,
  EnhancementGetResponse,
  EnhancementIdResponse,
  EnhancementPostBody,
  EnhancementPutBody,
} from '../models/enhancement'

const requestsBaseURL = 'v2/MyActions'
export const v2MyActionsService = {
  getRequests: async (
    params: EnhancementRequestParams,
    abortController: AbortController | null = null
  ): Promise<ManagerRequests> => {
    const requestURL =
      `${requestsBaseURL}/requests` +
      `?DirectReportees=${params.directReportees}` +
      `&DateFrom=${format(params.dateFrom, 'yyyy-MM-dd')}` +
      `&DateTo=${format(params.dateTo, 'yyyy-MM-dd')}` +
      `${params.departmentId ? `&DepartmentId=${params.departmentId}` : ``}` +
      `${params.departmentTeamId ? `&DepartmentTeamId=${params.departmentTeamId}` : ``}` +
      `&RequestStatusId=${params.requestStatusId >= 0 ? params.requestStatusId : 6}`

    const config = abortController ? { signal: abortController.signal } : undefined
    return httpServiceV2.get(requestURL, config)
  },

  postEnhancement: async (body: EnhancementPostBody): Promise<EnhancementIdResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/request/enhancement`, body),
  postEnhancementManual: async (body: EnhancementPostBody): Promise<EnhancementIdResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/request/manual/enhancement`, body),

  putEnhancement: async (body: EnhancementPutBody): Promise<EnhancementIdResponse> =>
    httpServiceV2.put(`${requestsBaseURL}/request/enhancement`, body),

  putEhancementManual: async (body: EnhancementPutBody): Promise<EnhancementIdResponse> =>
    httpServiceV2.put(`${requestsBaseURL}/request/manual/enhancement`, body),

  deleteEnhancement: async (enhancementId: number): Promise<EnhancementIdResponse> =>
    httpServiceV2.del(`${requestsBaseURL}/enhancement/${enhancementId}`),

  deleteEnhancementForManager: async (enhancementId: number): Promise<EnhancementIdResponse> =>
    httpServiceV2.del(`${requestsBaseURL}/enhancement/manager/${enhancementId}`),

  getEnhancement: async (enhancementId: number): Promise<EnhancementGetResponse> =>
    httpServiceV2.get(`${requestsBaseURL}/enhancement/${enhancementId}`),

  getEnhancementForManager: async (enhancementId: number): Promise<EnhancementGetResponse> =>
    httpServiceV2.get(`${requestsBaseURL}/enhancement/manager/${enhancementId}`),

  postBulkSubmitApprove: async (body: BulkSubmitRequest): Promise<BulkSubmitResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/request/bulk/submit/approve`, body),

  postBulkSubmitDecline: async (body: BulkSubmitRequest): Promise<BulkSubmitResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/request/bulk/submit/decline`, body),
}
