import { DataGridPro } from '@mui/x-data-grid-pro'
import styled from 'styled-components'

export const SytledMyActionsGrid = styled(DataGridPro)(() => ({
  '& div[data=field="__detail_panel_toggle__"]': {
    display: 'none',
  },
  '&.MuiDataGrid-root': {
    border: 'none !important ',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    minHeight: '200px!important',
  },
  '.header, .MuiDataGrid-columnHeaders': {
    backgroundColor: 'rgb(247, 247, 247)',
  },
  '& .MuiDataGrid-row': {
    '&.expanded': {
      opacity: '0.5',
    },
    '&.expanded .MuiDataGrid-cell': {
      borderBottomColor: 'transparent',
    },
  },
  '.MuiDataGrid-detailPanel': {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  cursor: 'pointer',
}))
