import { CurrentEntitlementPeriodResponse, SelectOption } from '../../services/dashboardService'
import { EntitlementPeriodType } from '../../utils/constants'

export function setYear(
  year: number,
  entitlement: CurrentEntitlementPeriodResponse | undefined
): SelectOption | null {
  if (!entitlement) {
    return null
  }

  const yearValue: SelectOption = {
    value: year,
    displayValue:
      entitlement.entitlementPeriod === 'CalendarYear' ? String(year) : `${year}/${year + 1}`,
    entitlementPeriod: entitlement.entitlementPeriod as EntitlementPeriodType,
  }

  return yearValue
}
