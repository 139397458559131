import { useEffect, useState } from 'react'
import { Grid, IconButton, Skeleton, Stack, useMediaQuery } from '@mui/material'
import { ChevronLeft, ChevronRight, Apps, Menu } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { ToggleButton, ToggleButtonGroup } from '../../../shared/UI/ToggleButton'
import theme from '../../../theme/theme'
import Paragraph from '../../../shared/UI/Paragraph/index'
import { MyRequestsTogglesProps, MyRequestsViewType } from './types'
import { RootStore, useAppDispatch } from '../../../redux/store'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import { setSelectedYear } from '../../../redux/reducers/myRequestsReducer'
import { isMobileDown } from '../../../theme/deviceChecks'
import { setYear } from '../utils'

export function MyRequestsToggles({
  viewType,
  viewTypeChange,
  setChangingYear,
  changingYear = false,
}: MyRequestsTogglesProps) {
  const dispatch = useAppDispatch()
  const {
    currentEntitlementPeriodResponse: entitlement,
    calendarDetailsResponse: financialYearCalendarDetails,
  } = useSelector((state: RootStore) => state.appSettings)
  const { selectedYear } = useSelector((state: RootStore) => state.myRequests)

  const [isBackDisabled, setIsBackDisabled] = useState<boolean>(true)
  const [isForwardDisabled, setIsForwardDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (financialYearCalendarDetails && selectedYear) {
      const years = financialYearCalendarDetails.calendarDetails
        .map(details => details.year)
        .sort((a, b) => a - b)
      setIsBackDisabled(years[0] >= selectedYear?.value)
      setIsForwardDisabled(years[years.length - 1] <= selectedYear?.value)
    }
  }, [financialYearCalendarDetails, selectedYear])

  // TODO moved setSelectedYear functionality to MyRequests/index.tsx. Below commented code left in as showErrorMessage logic causes the error to show on every page load, but is probably required in some form. Look at in Redux rework

  // useEffect(() => {
  //   if (!entitlement) {
  //     dispatch(showErrorMessage('Entitlement Period not found'))
  //     return
  //   }

  //   dispatch(setSelectedYear(setYear(entitlement.year, entitlement)))

  // }, [entitlement, dispatch])

  const mobile = useMediaQuery(isMobileDown())

  return (
    <Grid container>
      {!mobile && (
        <Grid item xs={9}>
          <ToggleButtonGroup
            value={null}
            exclusive
            bgColor={theme.palette.primary.main}
            minWidth="0"
            onChange={(_a, b) => viewTypeChange?.(b)}
          >
            <ToggleButton
              value={MyRequestsViewType.CALENDAR}
              fixedColor="white"
              width="110px"
              inactive={viewType !== MyRequestsViewType.CALENDAR}
              data-testid="MyAvailability-CalendarBtn"
            >
              <Apps />
              <Paragraph color="white" padding="0 5px" textTransform="capitalize">
                Calendar
              </Paragraph>
            </ToggleButton>
            <ToggleButton
              value={MyRequestsViewType.GRID}
              fixedColor="white"
              width="75px"
              inactive={viewType !== MyRequestsViewType.GRID}
              data-testid='MyAvailability-GridBtn"'
            >
              <Paragraph color="white" padding="0 5px" textTransform="capitalize">
                Grid
              </Paragraph>
              <Menu />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      <Grid
        display="flex"
        item
        xs={mobile ? 12 : 3}
        justifyContent={mobile ? 'space-between' : 'flex-end'}
      >
        {mobile && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            pr={2}
            ml={2}
          >
            <IconButton
              onClick={() => {
                if (!selectedYear) {
                  return
                }

                dispatch(setSelectedYear(setYear(selectedYear.value - 1, entitlement)))
              }}
              disabled={isBackDisabled}
            >
              <ChevronLeft
                sx={{
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
            <Paragraph size="16px" weight="bold">
              {selectedYear?.displayValue}
            </Paragraph>
            <IconButton
              onClick={() => {
                if (!selectedYear) {
                  return
                }
                dispatch(setSelectedYear(setYear(selectedYear.value + 1, entitlement)))
              }}
              disabled={isForwardDisabled}
            >
              <ChevronRight
                sx={{
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
          </Stack>
        )}
        {!mobile && (
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            {changingYear ? (
              <Skeleton
                variant="rectangular"
                sx={{ width: '100px', height: '20px', margin: '6px 8px 6px 0' }}
              />
            ) : (
              <Paragraph size="16px" weight="bold" padding="4px 8px">
                {selectedYear?.displayValue}
              </Paragraph>
            )}
            <ToggleButtonGroup
              value={null}
              exclusive
              bgColor={theme.palette.primary.main}
              onChange={(_e, advance) => {
                if (!selectedYear) {
                  return
                }
                setChangingYear({ calendarLoading: true, textLoading: true })
                dispatch(
                  setSelectedYear(
                    setYear(
                      advance === 'Dec' ? selectedYear.value - 1 : selectedYear.value + 1,
                      entitlement
                    )
                  )
                )
              }}
            >
              <ToggleButton value="Dec" fixedColor="white" disabled={isBackDisabled}>
                <ChevronLeft />
              </ToggleButton>
              <ToggleButton value="Inc" fixedColor="white" disabled={isForwardDisabled}>
                <ChevronRight />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}
