import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Stack, Checkbox, TextField, Grid } from '@mui/material'
import { EnhancementDay } from '../../../models/enhancement'

interface DaysCheckboxListProps {
  days: EnhancementDay[]
  onDaysChange: (days: EnhancementDay[]) => void
}
export default function DaysCheckboxList({ days, onDaysChange }: DaysCheckboxListProps) {
  const [enhancementDays, setEnhancementDays] = useState<EnhancementDay[]>(days)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedDays = [...enhancementDays]
    updatedDays[index].checked = e.target.checked
    setEnhancementDays(updatedDays)
    onDaysChange(updatedDays)
  }

  const handleHoursChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const updatedDays = [...enhancementDays]
    updatedDays[index].hours = e.target.value ? Number(e.target.value) : 0
    setEnhancementDays(updatedDays)
    onDaysChange(updatedDays)
  }

  return (
    <Grid container gap={3}>
      {enhancementDays?.map((day, index) => (
        <Grid item xs={12} key={day?.day?.toString()}>
          <Stack direction="row" gap={2}>
            <Checkbox checked={day.checked} onChange={e => handleCheckboxChange(e, index)} />
            <TextField
              type="number"
              InputProps={{
                inputProps: { step: '0.1', min: 0.1, max: 24 },
              }}
              fullWidth
              label={format(new Date(day.day!), 'ccc dd/MM/yyyy')}
              defaultValue={day.hours}
              value={day.hours}
              onChange={e => handleHoursChanged(e, index)}
            />
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}
