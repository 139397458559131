import { SmallButton } from './components'
import { ButtonSmallProps } from './types'

function ButtonSmall({
  dataTestId,
  variant = 'outlined',
  onClick,
  label,
  disabled,
  color = 'primary',
  type,
  style,
  minWidth,
  sx,
  startIcon,
}: ButtonSmallProps) {
  return (
    <SmallButton
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      color={color}
      type={type}
      minWidth={minWidth}
      style={style}
      sx={sx}
      startIcon={startIcon}
      data-testid={dataTestId}
    >
      {label}
    </SmallButton>
  )
}

export default ButtonSmall
