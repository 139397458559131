import { DateRange } from '@mui/x-date-pickers-pro'
import { SelectOption, Employee, Team } from '../../services/dashboardService'

export interface MyActionsState {
  selectedDepartmentFilter: SelectOption | null | undefined
  selectedTeamsFilter: Team[] | []
  selectedTeamFilter: Team | null
  dateRangeFilter: DateRange<Date> | [null, null]
  statusFilter: number
}

export const myActionsInitialState: MyActionsState = {
  selectedDepartmentFilter: null,
  selectedTeamsFilter: [],
  selectedTeamFilter: null,
  dateRangeFilter: [null, null],
  statusFilter: 97,
}

const SET_SELECTED_DEPARTMENT = 'SET_SELECTED_DEPARTMENT'
const SET_SELECTED_TEAMS = 'SET_SELECTED_TEAMS'
const SET_SELECTED_TEAM = 'SET_SELECTED_TEAM'
const SET_SELECTED_DATE_RANGE = 'SET_SELECTED_DATE_RANGE'
const SET_SELECTED_STATUS = 'SET_SELECTED_STATUS'

interface SetSelectedDepartmentFilterAction {
  type: typeof SET_SELECTED_DEPARTMENT
  payload: SelectOption | null | undefined
}

interface setSelectedTeamsFilterAction {
  type: typeof SET_SELECTED_TEAMS
  payload: Team[] | []
}

interface SetSelectedTeamFilterAction {
  type: typeof SET_SELECTED_TEAM
  payload: Team | null
}

interface SetSelectedDateRangeFilterAction {
  type: typeof SET_SELECTED_DATE_RANGE
  payload: DateRange<Date> | [null, null]
}

interface SetSelectedStatusAction {
  type: typeof SET_SELECTED_STATUS
  payload: number
}

type myActionsDispatchtypes =
  | SetSelectedDepartmentFilterAction
  | SetSelectedTeamFilterAction
  | SetSelectedDateRangeFilterAction
  | SetSelectedStatusAction
  | setSelectedTeamsFilterAction

// Actions
export const setSelectedDepartmentFilter = (
  departmentId: SelectOption | null | undefined
): SetSelectedDepartmentFilterAction => ({
  type: SET_SELECTED_DEPARTMENT,
  payload: departmentId,
})

export const setSelectedTeamsFilter = (teams: Team[] | []): setSelectedTeamsFilterAction => ({
  type: SET_SELECTED_TEAMS,
  payload: teams,
})

export const setSelectedTeamFilter = (teamId: Team | null): SetSelectedTeamFilterAction => ({
  type: SET_SELECTED_TEAM,
  payload: teamId,
})

export const setSelectedDateRangeFilter = (
  dateRangeFilter: DateRange<Date> | [null, null]
): SetSelectedDateRangeFilterAction => ({
  type: SET_SELECTED_DATE_RANGE,
  payload: dateRangeFilter,
})

export const setSelectedStatusFilter = (statusFilter: number): SetSelectedStatusAction => ({
  type: SET_SELECTED_STATUS,
  payload: statusFilter,
})

// Reducer
export const myActionsReducer = (
  state: MyActionsState = myActionsInitialState,
  action: myActionsDispatchtypes
): MyActionsState => {
  switch (action.type) {
    case SET_SELECTED_DEPARTMENT:
      return { ...state, selectedDepartmentFilter: action.payload }
    case SET_SELECTED_TEAM:
      return { ...state, selectedTeamFilter: action.payload }
    case SET_SELECTED_TEAMS:
      return { ...state, selectedTeamsFilter: action.payload }
    case SET_SELECTED_DATE_RANGE:
      return { ...state, dateRangeFilter: action.payload }
    case SET_SELECTED_STATUS:
      return { ...state, statusFilter: action.payload }
    default:
      return state
  }
}
