/* eslint-disable no-shadow */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { RootStore } from '../../redux/store'
import { EnhancementsSummaryTable } from './EnhancementSummary/EnhancementSummaryTable'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import { enhancementsContent } from '../../utils/constants'
import { enhancementService } from '../../services/enhancementService'
import {
  EnhancementApprovalViewResponse,
  EnhancementStub,
  SubmissionPeriod,
} from '../../models/enhancement'
import { enhancementFilter } from './enhancementSummaryFilter'
import { EmployeeDetailsResponse } from '../../types/employee'
import { hasUserAccessToRoute } from '../../utils/app-utils'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import { PayPeriodBanner } from './PayPeriodBanner/PayPeriodBanner'
import {
  TrendIndicator,
  TrendIndicatorProps,
  TrendIndicatorStats,
} from '../../shared/UI/TrendIndicatorCard/TrendIndicator'
import { trendIncdicatorThemes } from '../../shared/UI/TrendIndicatorCard/TrendStyles'
import { getStats } from './statsProvider'

export function Enhancements() {
  /*
   * Start feature flag code
   * Check if user has the permissions to view this page and the feature toggle is on
   */
  const navigate = useNavigate()
  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const featureToggles = useSelector((state: RootStore) => state.featureToggles)
  // Check if user has the permissions to view this page and the feature toggle is on
  useEffect(() => {
    if (
      userPermissions?.length > 0 &&
      featureToggles?.enhancement !== undefined &&
      !hasUserAccessToRoute('/enhancements', userPermissions, employeeDetails.isContractor)
    ) {
      navigate('/dashboard')
    }
  }, [employeeDetails.isContractor, featureToggles, navigate, userPermissions])
  /*
   * End feature flag code
   */

  /*
   * Set the initial state of page data
   */
  const initialState: EnhancementApprovalViewResponse = {
    enhancements: [],
    errors: [],
  }
  const defaultVals: EnhancementApprovalViewResponse = {
    enhancements: [],
    errors: [],
  }
  const [lastResponse, setLastResponse] = useState<EnhancementApprovalViewResponse>(defaultVals)
  const [filteredEhancements, setFilteredEnhancements] = useState<EnhancementStub[]>([])
  const filterArgs = useRef<string[]>([])

  interface Stats {
    onCall?: TrendIndicatorStats
    calledOut?: TrendIndicatorStats
    overtime?: TrendIndicatorStats
    night?: TrendIndicatorStats
    total?: TrendIndicatorStats
  }

  const stats = useRef<Stats>({})

  const fetchApprovals = (submissionPeriod?: SubmissionPeriod) => {
    enhancementService.getApprovals(submissionPeriod).then(response => {
      const { enhancements, enhancementStatsPreviousPeriod } = response
      const statsObject: Stats = {}
      if (enhancementStatsPreviousPeriod) {
        statsObject.calledOut = getStats(
          enhancements,
          enhancementStatsPreviousPeriod.calledOut.hours,
          enhancementsContent.callOut
        )
        statsObject.night = getStats(
          enhancements,
          enhancementStatsPreviousPeriod.night.hours,
          enhancementsContent.night
        )
        statsObject.onCall = getStats(
          enhancements,
          enhancementStatsPreviousPeriod.onCall.hours,
          enhancementsContent.onCall
        )
        statsObject.overtime = getStats(
          enhancements,
          enhancementStatsPreviousPeriod.overtime.hours,
          enhancementsContent.overtime
        )
        statsObject.total = getStats(enhancements, enhancementStatsPreviousPeriod?.total.hours, '')
      }
      stats.current = statsObject

      setLastResponse(response)
      setFilteredEnhancements(enhancementFilter(response.enhancements, filterArgs.current))
    })
  }

  const filterEnhancements = (args: string | undefined) => {
    const array = args ? [args] : []

    filterArgs.current = array
    setFilteredEnhancements(enhancementFilter(lastResponse.enhancements, array))
  }

  const getApprovals = useCallback((submissionPeriod?: SubmissionPeriod): void => {
    fetchApprovals(submissionPeriod)
  }, [])

  useEffect(() => {
    fetchApprovals()
  }, [])

  const navigateNext = () => {
    getApprovals(lastResponse.payPeriod?.nextPeriod)
  }

  const navigatePrevious = () => {
    getApprovals(lastResponse.payPeriod?.previousPeriod)
  }

  const isActive = (filterArg: string | undefined) => {
    if (filterArg) return filterArgs.current.includes(filterArg)
    return filterArgs.current?.length === 0
  }

  const defaultStats: TrendIndicatorStats = { percentage: 0, hours: 0 }

  const filters: TrendIndicatorProps[] = [
    {
      testId: 'on-call',
      theme: trendIncdicatorThemes.onCall,
      filterArg: enhancementsContent.onCall,
      stats: stats.current.onCall ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'call-out',
      theme: trendIncdicatorThemes.callOut,
      filterArg: enhancementsContent.callOut,
      stats: stats.current.calledOut ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'overtime',
      theme: trendIncdicatorThemes.overtime,
      filterArg: enhancementsContent.overtime,
      stats: stats.current.overtime ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'night',
      theme: trendIncdicatorThemes.night,
      filterArg: enhancementsContent.night,
      stats: stats.current.night ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'total',
      theme: trendIncdicatorThemes.total,
      stats: stats.current.total ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
  ]

  const renderPayPeriodBanner = () => {
    if (!lastResponse.payPeriod) return
    const { submittedOn, submitBy, dateRange, currentPeriod, previousPeriod, nextPeriod } =
      lastResponse.payPeriod
    return (
      <PayPeriodBanner
        status={submittedOn ? 'submitted' : 'pending'}
        statusDate={submittedOn ?? submitBy}
        dateRange={dateRange}
        currentPayPeriod={currentPeriod?.period ?? 1}
        disableNext={!nextPeriod}
        disablePrevious={!previousPeriod}
        onNext={() => {
          navigateNext()
        }}
        onPrevious={() => {
          navigatePrevious()
        }}
      />
    )
  }

  return (
    <>
      {userPermissions?.length < 1 || !featureToggles?.enhancement ? (
        <LoadingIndicator show />
      ) : (
        <>
          <MyActionsPageHeader title={enhancementsContent.enhancements} />
          {renderPayPeriodBanner()}

          <Box py="10px" mb="20px" display="flex" gap="24px">
            {filters.map(filter => (
              <TrendIndicator {...filter} key={filter.filterArg} />
            ))}
          </Box>
          <EnhancementsSummaryTable data={filteredEhancements} title={enhancementsContent.total} />
        </>
      )}
    </>
  )
}
