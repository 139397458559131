import { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { Box, Drawer as MuiDrawer, useMediaQuery, Divider } from '@mui/material'
import DrawerHeader from '../DrawerHeader'
import { isMobileDown, isMobilePortrait } from '../../../theme/deviceChecks'
import { CloseDrawerButton, StyledDrawer } from './components'

interface DrawerProps {
  isOpen: boolean
  onClose: (event?: any, reason?: string) => void
  title?: string
  showOptions?: boolean
  children: any
  narrow?: boolean
  status?: string
  anchor?: 'bottom' | 'right' | 'left' | 'top' | undefined
  className?: string
  mobileHeight?: string | undefined
}

function Drawer({
  isOpen,
  onClose,
  title,
  children,
  showOptions = false,
  narrow = false,
  status,
  anchor,
  className,
  mobileHeight,
}: DrawerProps) {
  const mobilePortrait = useMediaQuery(isMobilePortrait())
  const mobile = useMediaQuery(isMobileDown())

  const whichAnchor = useMemo(() => {
    if (anchor) {
      return anchor
    }
    return mobilePortrait ? 'bottom' : 'right'
  }, [anchor, mobilePortrait])

  const drawerClass = useMemo(() => `${className} ${isMobile && 'mobileDrawer'}`, [className])

  return (
    <StyledDrawer
      anchor={whichAnchor}
      open={isOpen}
      onClose={onClose}
      narrow={narrow}
      className={drawerClass}
      mobileHeight={mobileHeight}
    >
      <Box p={4}>
        {title && (
          <>
            <DrawerHeader title={title} showOptions={showOptions} status={status} />
            {!mobilePortrait && (
              <Box pl={4} pr={4}>
                <Divider />
              </Box>
            )}
          </>
        )}

        {children}
      </Box>
      {!mobile && !isMobile && <CloseDrawerButton onClick={onClose} />}
    </StyledDrawer>
  )
}
export default Drawer
