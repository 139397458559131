import { Grid, Stack, TextField, Typography } from '@mui/material'
import { format } from 'date-fns'
import { EnhancementGetResponse } from '../../../models/enhancement'
import CardTitle from '../../../shared/UI/CardTitle'
import { globalContent, callOutFromContent, enhancementsContent } from '../../../utils/constants'
import Card from '../../../shared/layout/Card'
import { totalHours } from '..'

export const getDetailItem = (label: string, value: string) => (
  <Typography sx={{ width: '50%', display: 'flex', gap: 1 }}>
    <strong>{label}</strong>
    <span>{value}</span>
  </Typography>
)
export function TotalHoursCard({
  enhancementType,
  dateRange,
  calloutDetails,
  days,
}: EnhancementGetResponse) {
  const isCalloutEnhancment =
    enhancementType.toLowerCase() === enhancementsContent.callOut.toLowerCase()

  /**
   * Should display Reason for Call out and Comment for all other enhancements
   * Comments are not being received form backend in enhancement request
   * */

  const comment = isCalloutEnhancment ? calloutDetails?.reason : ''
  const displayDateType = isCalloutEnhancment ? 'Pp' : 'P'
  return (
    <Card
      title={globalContent.totalHours}
      icon={<CardTitle title={totalHours(days)} />}
      testId="total-hours"
    >
      <Grid container spacing={4}>
        <Grid item xs={12} textAlign="left">
          <Stack direction="row" spacing={1} mb={1}>
            {getDetailItem(
              globalContent.dateFrom,
              format(new Date(dateRange.start), displayDateType)
            )}
            {getDetailItem(globalContent.dateTo, format(new Date(dateRange.end), displayDateType))}
          </Stack>
          {isCalloutEnhancment && calloutDetails ? (
            <>
              <Stack direction="row" spacing={1} mb={1}>
                {getDetailItem(callOutFromContent.calledOut, calloutDetails.calledOutBy)}
                {getDetailItem(callOutFromContent.product, calloutDetails.product)}
              </Stack>
              <Stack direction="row" spacing={1} mb={1}>
                {getDetailItem(
                  callOutFromContent.requireEscalation,
                  calloutDetails.escalationRequired ? 'Yes' : 'No'
                )}
                {getDetailItem(callOutFromContent.issueType, calloutDetails.issueType)}
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography sx={{ display: 'flex', gap: 2 }}>
                  <strong>{callOutFromContent.jiraReference}</strong>
                  <span>{calloutDetails.jiraReference}</span>
                </Typography>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={
              isCalloutEnhancment
                ? callOutFromContent.issueDescription
                : globalContent.requesteComments
            }
            fullWidth
            multiline
            rows={4}
            disabled
            value={comment}
            data-testid="description"
          />
        </Grid>
      </Grid>
    </Card>
  )
}
