import { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Stack, TextField } from '@mui/material'
import { format } from 'date-fns'

import { DateRange } from '@mui/x-date-pickers-pro'
import { StyledGrid } from '../../OccurrenceRequest'
import { EnhancementDay, EnhancementGetResponse, SubmitStatus } from '../../../models/enhancement'
import Button from '../../../shared/UI/Button'
import DaysCheckboxList from '../DaysCheckboxList/DaysCheckboxList'
import Card from '../../../shared/layout/Card'
import { hasDaysChanged } from '../hasDaysChanged'
import Modal from '../../../shared/UI/Modal'
import { RootStore, useAppDispatch } from '../../../redux/store'
import {
  SetShowModalPayload,
  hideModal,
  showModalDialog,
} from '../../../redux/reducers/appSettingsReducer'
import DateTimePicker from '../../../shared/UI/DateTimePicker'
import { callOutFromContent, enhancementsContent, globalContent } from '../../../utils/constants'
import { ValidaitonErrors } from '../../CallOutEnhancement/validations'
import { formatDateWithTimeZoneStr, getDurationInFractionalHours } from '../../../utils/date-utils'
import Alert from '../../../shared/UI/Alert/Alert'
import { calculateCalloutDays } from '../../../utils/RequestBuilder/requestBuilder'
import { DateFormats } from '../../../api/absence/tempTypes/generalprops'
import { v2MyActionsService } from '../../../services/myActionsServiceV2'
import { showErrorMessage, showSuccessMessage } from '../../../redux/reducers/snackbarReducer'

export default function Approval({ days, id, dateRange, enhancementType }: EnhancementGetResponse) {
  const dispatch = useAppDispatch()

  const [requestedDays, setRequestedDays] = useState<EnhancementDay[]>(days!)
  const [hasChanges, setHasChanges] = useState<boolean>()
  const [comments, setComments] = useState<string>()
  const [commentsError, setCommentsError] = useState<boolean>()
  const [requestedDateRange, setRequstedDateRange] = useState<DateRange<Date>>([
    new Date(dateRange.start),
    new Date(dateRange.end),
  ])
  const [formErrors, setFormErrors] = useState<ValidaitonErrors>()
  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const isCalloutEnhancment =
    enhancementType.toLowerCase() === enhancementsContent.callOut.toLowerCase()

  /**
   * Check if the selected date time is in 12 hours range.
   */
  const validateDuration = () => {
    const [start, end] = requestedDateRange
    if (start && end) {
      const hours = getDurationInFractionalHours(start, end)
      setFormErrors(prevState => ({
        ...prevState,
        start: false,
        end: false,
        duration: hours <= 0 || hours > 12,
      }))
    }
  }

  useEffect(() => {
    validateDuration()
  }, [requestedDateRange])

  useEffect(() => {
    if (isCalloutEnhancment && requestedDateRange[0] && requestedDateRange[1]) {
      const allDays = calculateCalloutDays(requestedDateRange)
      setRequestedDays(
        allDays.map(day => ({
          day: formatDateWithTimeZoneStr(day.day),
          hours: day.hours,
          checked: day.checked,
        }))
      )
    }
  }, [requestedDateRange])

  const handleDaysChange = (updatedDays: EnhancementDay[]) => {
    setHasChanges(hasDaysChanged(days!, updatedDays))
    setRequestedDays(updatedDays)
  }

  const hasDateRangeChanged =
    requestedDateRange[0]?.getTime() !== new Date(dateRange.start).getTime() ||
    requestedDateRange[1]?.getTime() !== new Date(dateRange.end).getTime()

  const validateDateRange = () => {
    if (!requestedDateRange[0] || !requestedDateRange[1]) {
      setFormErrors(prevState => ({
        ...prevState,
        start: !requestedDateRange[0],
        end: !requestedDateRange[1],
      }))
      return false
    }
  }

  const putRequest = (submitStatus: SubmitStatus, messageText: string) => {
    validateDateRange()
    v2MyActionsService
      .putEhancementManual({
        id,
        days: requestedDays,
        dateRange: {
          start: format(requestedDateRange[0]!, DateFormats.DATE_AND_TIME),
          end: format(requestedDateRange[1]!, DateFormats.DATE_AND_TIME),
        },
        status: {
          submitStatus,
          comments,
        },
      })
      .then(() => {
        dispatch(showSuccessMessage(`Request successfully ${messageText}!`))
      })
      .catch(() => {
        dispatch(showErrorMessage(`Failed request has not been ${messageText}!`))
      })
  }

  const declineRequest = () => {
    putRequest(SubmitStatus.DECLINED, 'declined')
  }

  const approveRequest = () => {
    putRequest(SubmitStatus.APPROVED, 'approved')
  }

  const displayClashModal = () => {
    if (!comments) return setCommentsError(!comments)
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to decline this request?`,
        buttonLabel: 'Decline',
        type: 'question',
        showModal: true,
      })
    )
  }

  const handleCommentChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setComments(e.target.value)
    if (e.target.value) {
      setCommentsError(false)
    }
  }

  return (
    <Card title="Approval">
      <Grid container spacing={4}>
        <StyledGrid
          item
          xs={12}
          sx={{
            overflowY: 'scroll',
            maxHeight: '250px',
            marginTop: '20px',
          }}
        >
          {isCalloutEnhancment ? (
            <>
              <Grid item xs={12}>
                <DateTimePicker
                  value={requestedDateRange[0]}
                  maxDate={new Date()}
                  onChange={date => setRequstedDateRange([date, requestedDateRange[1]])}
                  label={globalContent.from}
                  error={formErrors?.start}
                  helperText={globalContent.required}
                  dataTestId="callout-from-date"
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <DateTimePicker
                  value={requestedDateRange[1]}
                  maxDate={new Date()}
                  onChange={date => setRequstedDateRange([requestedDateRange[0], date])}
                  label={globalContent.to}
                  error={formErrors?.end}
                  helperText={globalContent.required}
                  dataTestId="callout-to-date"
                />
                {formErrors?.duration ? (
                  <Alert severity="error" message={callOutFromContent.durationError} />
                ) : null}
              </Grid>
            </>
          ) : (
            <DaysCheckboxList
              days={requestedDays}
              onDaysChange={updatedDays => {
                handleDaysChange(updatedDays)
              }}
            />
          )}
        </StyledGrid>
        <Grid item xs={12}>
          <Stack direction="row">
            <TextField
              label="Reviewer comments"
              fullWidth
              multiline
              rows={4}
              value={comments}
              sx={{ paddingRight: '6px' }}
              onChange={e => handleCommentChange(e)}
              error={commentsError}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingRight: '6px',
            '&.MuiGrid-item': {
              paddingTop: '0px',
            },
          }}
        >
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            justifyContent="space-between"
            gap={{ xs: 0, md: 4 }}
          >
            <Button
              label="Decline"
              variant="outlined"
              color="secondary"
              onClick={displayClashModal}
            />
            <Button
              label={hasChanges || hasDateRangeChanged ? 'Approve With Changes' : 'Approve'}
              variant="outlined"
              color="primary"
              onClick={approveRequest}
            />
          </Stack>
        </Grid>
      </Grid>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={declineRequest}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
    </Card>
  )
}
