/**
 * A radio group to confirm an action
 */
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TooltipProps,
  tooltipClasses,
  Tooltip,
  Typography,
} from '@mui/material'
import styled from 'styled-components'
import React, { useState } from 'react'
import { Info } from '@mui/icons-material'

interface RowRadioGroupProps {
  label: string
  value: boolean
  tooltipText?: string
  testid?: string
  onRadioChange: (newValue: boolean) => void
}

export const styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '16px 0 -24px 32px',
  width: '100%',
}
const options = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
]
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    background: '#F4F5F7',
    color: '#555555',
    boxShadow: '0 0 10px #00000015',
  },
})
export function RowRadioGroup({
  label,
  value,
  onRadioChange,
  tooltipText,
  testid = 'radio-group',
}: RowRadioGroupProps) {
  const [selectedOption, setSelectedOption] = useState<boolean>(value)
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value === 'true'
    setSelectedOption(option)
    onRadioChange(option)
  }

  return (
    <FormControl sx={styles}>
      <Typography style={{ display: 'flex', gap: '4px' }}>
        {label}
        {tooltipText ? (
          <CustomWidthTooltip title={tooltipText} data-testid={`${testid}-tooltip`}>
            <Info fontSize="small" sx={{ color: '#20C5A0' }} />
          </CustomWidthTooltip>
        ) : null}
      </Typography>
      <RadioGroup row value={selectedOption} onChange={handleOnChange} data-testid={testid}>
        {options.map(option => (
          <FormControlLabel
            value={option.value}
            control={<Radio name={option.label} />}
            label={option.label}
            key={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
