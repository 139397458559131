import { FormControlLabel, Grid, IconButton, Tooltip, useMediaQuery } from '@mui/material'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import TuneIcon from '@mui/icons-material/Tune'
import { useDispatch, useSelector } from 'react-redux'
import { MyActionsGrid } from '../MyActionsGrid'
import { RootStore } from '../../redux/store'
import { manualRequestsService } from '../../services/myActionsService'
import {
  setAbsenceArray,
  setFilteredAbsences,
  setTabSelected,
  setDirectReportsToggle,
} from '../../redux/reducers/timeOffRequestsReducer'
import theme from '../../theme/theme'
import IndividualAbsenceType from './IndividualAbsenceType'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import { FlattenedRequest } from '../../types/flattened-requests'
import { PageContext } from '../../context/MyActionsPageContext'
import { IOSStyleSwitch } from '../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'
import Paragraph from '../../shared/UI/Paragraph'
import { isMobileDown } from '../../theme/deviceChecks'
import MobileContainer from '../../shared/layout/MobileContainer'
import { enhancementsContent } from '../../utils/constants'

function MyActions() {
  const mobile = useMediaQuery(isMobileDown())
  const dispatch = useDispatch()

  const [showFilter, setShowFilter] = useState(false)
  const requestStatusId = 6 // Pending

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const startDate = currentEntitlementPeriodResponse?.startDate
  const endDate = currentEntitlementPeriodResponse?.endDate

  const {
    absenceArray: employeeAbsences,
    directReportsToggle,
    filteredAbsences,
    absenceArrayDirectReports: employeeAbsencesDirectReports,
    tabSelected: selected,
    actionFilterSelected,
  } = useSelector((state: RootStore) => state.timeOff)

  const getAbsenceData = useCallback(async () => {
    if (!startDate || !endDate || !currentEntitlementPeriodResponse) {
      return
    }

    const startDateRequest = new Date(
      new Date(currentEntitlementPeriodResponse.startDate).getFullYear(),
      new Date(currentEntitlementPeriodResponse.startDate).getMonth(),
      new Date(currentEntitlementPeriodResponse.startDate).getDate()
    )
    const endDateRequest = new Date(
      new Date(currentEntitlementPeriodResponse.endDate).getFullYear(),
      new Date(currentEntitlementPeriodResponse.endDate).getMonth(),
      new Date(currentEntitlementPeriodResponse.endDate).getDate()
    )

    await manualRequestsService
      .getRequests(
        directReportsToggle,
        startDateRequest,
        endDateRequest,
        undefined,
        undefined,
        requestStatusId
      )
      .then(absence => {
        dispatch(setAbsenceArray(absence.requests))
      })
  }, [currentEntitlementPeriodResponse, directReportsToggle, dispatch, endDate, startDate])

  const getCount = (absenceType?: string) => {
    let result: FlattenedRequest[] | undefined = []
    const getAbsenceType = () =>
      employeeAbsences?.filter((absence: FlattenedRequest) => absence.requestType === absenceType)

    if (absenceType === 'All') return employeeAbsences?.length

    result = getAbsenceType()
    return result?.length
  }

  const filterAbsences = useCallback(() => {
    if (actionFilterSelected === '') {
      return
    }

    const setType = employeeAbsences

    const result: FlattenedRequest[] = []
    dispatch(setTabSelected(actionFilterSelected))

    setType?.forEach((e: FlattenedRequest) => {
      if (mobile) {
        if (e?.requestType === 'Holiday') {
          result.push(e)
        }
      } else if (e?.requestType === actionFilterSelected || actionFilterSelected === 'All')
        result.push(e)
    })

    dispatch(setFilteredAbsences(result))
  }, [actionFilterSelected, dispatch, employeeAbsences, mobile])

  useEffect(() => {
    filterAbsences()
  }, [actionFilterSelected, filterAbsences])

  const tabDetailLabels = ['All', 'Holidays', 'Absences', 'Lates', 'Day Off', 'Work From Home']
  const tabDetailTypes = ['All', 'Holiday', 'Absence', 'Late', 'Day Off', 'Work From Home']

  const { enhancement } = useSelector((state: RootStore) => state.featureToggles)
  if (enhancement) {
    const enhacements = [
      enhancementsContent.overtime,
      enhancementsContent.night,
      enhancementsContent.onCall,
      enhancementsContent.callOut,
    ]
    tabDetailLabels.push(...enhacements)
    tabDetailTypes.push(...enhacements)
  }

  const contextData = useMemo(() => ({ getAbsenceData }), [getAbsenceData])

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowFilter(!showFilter)
  }

  return (
    <PageContext.Provider value={contextData}>
      <MyActionsPageHeader />
      {!mobile && (
        <Grid container maxWidth="xl" spacing={4}>
          <Grid
            item
            xs={12}
            lg={9}
            display="flex"
            flexWrap="wrap"
            direction="row"
            alignItems="center"
            mb={3}
            gap={2}
          >
            <Paragraph weight="bold">Quick Filters:&nbsp;&nbsp;&nbsp;</Paragraph>
            {tabDetailTypes.map((tab, index) => (
              <IndividualAbsenceType
                absenceLabel={tabDetailLabels[index]}
                absenceType={tab}
                getCount={getCount}
                data-testid="MyActions-All"
              />
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            display="flex"
            justifyContent="flex-end"
            flexWrap="nowrap"
            gap={2}
            mb={3}
          >
            <FormControlLabel
              style={{
                gap: '10px',
                margin: '5px',
              }}
              control={
                <IOSStyleSwitch
                  checked={directReportsToggle}
                  data-testid="MyActions-DirectReports"
                />
              }
              label="Direct Reports"
              labelPlacement="start"
              onClick={() => dispatch(setDirectReportsToggle(!directReportsToggle))}
              sx={{ color: 'rgb(116, 116, 116) !important;' }}
            />
            <Tooltip title="Filters" placement="bottom">
              <IconButton onClick={handleFilterClick} data-testid="MyActions-FiltersBtn">
                <TuneIcon
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <MobileContainer>
        <Grid container spacing={4}>
          <Grid item xs>
            <MyActionsGrid showFilter={showFilter} />
          </Grid>
        </Grid>
      </MobileContainer>
    </PageContext.Provider>
  )
}

export default MyActions
