import LoadingButton from '@mui/lab/LoadingButton'

type Props = {
  variant?: 'text' | 'outlined' | 'contained' | undefined
  onClick?: ((event: any) => void) | undefined
  label: string | React.ReactElement | undefined
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined
  fullWidth?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  style?: any
  loading?: boolean
  dataTestId?: string
}

function Button({
  variant = 'outlined',
  onClick,
  label,
  color = 'primary',
  fullWidth,
  disabled,
  type,
  style,
  loading = false,
  dataTestId,
}: Props) {
  return (
    <LoadingButton
      loading={loading}
      variant={variant}
      onClick={onClick}
      color={color}
      fullWidth={fullWidth}
      disabled={disabled}
      type={type}
      style={style}
      data-testid={dataTestId}
    >
      {label}
    </LoadingButton>
  )
}

export default Button
