import { DateRange } from '@mui/x-date-pickers-pro'
import { Absence } from '../../components/Absence/types'
import { RequestDay, RequestType } from '../../models'
import { ConflictMatch } from '../../types/base-response'
import { EmployeeDetails } from '../../models/employee-details'
import { FlattenedRequest } from '../../types/flattened-requests'
import { AbsenceDay } from '../../types/absence-day'
import { AbsenceDayRequest } from '../../types/absence'
import { CalloutDetails } from '../../models/enhancement'

export interface timeOffRequestsState {
  dateRange: DateRange<Date>
  defaultDateRange?: DateRange<Date> | undefined
  selectedDays: AbsenceDayRequest[] | null
  selectedDate: Date | null
  selectedTime: Date | null
  comments: string
  requestType: RequestType
  employees: EmployeeDetails[] | null | undefined
  isIncrease: boolean
  adjustmentHours: number
  financialYear: string
  employeeArray: any
  employeeSelected: number | null
  absenceArray: FlattenedRequest[]
  absenceArrayDirectReports: FlattenedRequest[]
  directReportsToggle: boolean
  filteredAbsences: FlattenedRequest[] | undefined
  tabSelected: string
  alerts: ConflictMatch[] | null
  conflicts: boolean
  lateData: any
  actionFilterSelected: string
  calloutDetails: CalloutDetails
}
export const calloutInitialState: CalloutDetails = {
  calledOutBy: '',
  issueType: '',
  escalationRequired: false,
  escalationType: '',
  transferToAnotherTeam: false,
  escalatedTo: '',
  team: '',
  reason: '',
  conclusion: '',
  jiraReference: '',
  product: '',
  codeChange: false,
  logInRequired: false,
}
export const initialState: timeOffRequestsState = {
  dateRange: [null, null],
  defaultDateRange: undefined,
  selectedDays: null,
  selectedDate: null,
  selectedTime: null,
  comments: '',
  requestType: RequestType.HOLIDAY,
  employees: [],
  isIncrease: false,
  adjustmentHours: 0,
  financialYear: ``,
  employeeArray: [],
  employeeSelected: null,
  absenceArray: [],
  absenceArrayDirectReports: [],
  directReportsToggle: true,
  filteredAbsences: undefined,
  tabSelected: 'All',
  alerts: null,
  conflicts: false,
  lateData: null,
  actionFilterSelected: '',
  calloutDetails: calloutInitialState,
}

// Action Types
const SET_DATE_RANGE = 'SET_DATE_RANGE'
const SET_DEFAULT_DATE_RANGE = 'SET_DEFAULT_DATE_RANGE'
const SET_SELECTED_DAYS = 'SET_SELECTED_DAYS'
const SET_COMMENTS = 'SET_COMMENTS'
const SET_DATE = 'SET_DATE'
const SET_TIME = 'SET_TIME'
const SET_REQUEST_TYPE = 'SET_REQUEST_TYPE'
const RESET_NEW_REQUEST_STATE = 'RESET_NEW_REQUEST_STATE'
const SET_EMPLOYEES = 'SET_EMPLOYEES'
const SET_IS_INCREASE = 'SET_IS_INCREASE'
const SET_HOURS = 'SET_HOURS'
const SET_FINANCIAL_YEAR = 'SET_FINANCIAL_YEAR'
const SET_EMPLOYEE_ARRAY = 'SET_EMPLOYEE_ARRAY'
const SET_EMPLOYEE_SELECTED = 'SET_EMPLOYEE_SELECTED'
const SET_ABSENCE_ARRAY = 'SET_ABSENCE_ARRAY'
const SET_ABSENCE_ARRAY_DIRECT_REPORTS = 'SET_ABSENCE_ARRAY_DIRECT_REPORTS'
const SET_DIRECT_REPORTS_TOGGLE = 'SET_DIRECT_REPORTS_TOGGLE'
const SET_FILTERED_ABSENCES = 'SET_FILTERED_ABSENCES'
const SET_TAB_SELECTED = 'SET_TAB_SELECTED'
const SET_ALERTS = 'SET_ALERTS'
const SET_CONFLICTS = 'SET_CONFLICTS'
const SET_LATE_DATA = 'SET_LATE_DATA'
const SET_ACTION_FILTER_SELECTED = 'SET_ACTION_FILTER_SELECTED'
const SET_CALLOUT_DETAILS = 'SET_CALLOUT_DETAILS'

// Action Creators
interface SetDateRange {
  type: typeof SET_DATE_RANGE
  payload: DateRange<Date>
}

interface SetDefaultDateRange {
  type: typeof SET_DEFAULT_DATE_RANGE
  payload: DateRange<Date> | undefined
}

interface SetSelectedDays {
  type: typeof SET_SELECTED_DAYS
  payload: AbsenceDayRequest[] | null
}

interface SetComments {
  type: typeof SET_COMMENTS
  payload: string
}

interface SetDate {
  type: typeof SET_DATE
  payload: Date | null
}

interface SetTime {
  type: typeof SET_TIME
  payload: Date | null
}

interface SetRequestType {
  type: typeof SET_REQUEST_TYPE
  payload: RequestType
}

interface ResetNewRequestState {
  type: typeof RESET_NEW_REQUEST_STATE
}

interface SetEmployees {
  type: typeof SET_EMPLOYEES
  payload: EmployeeDetails[] | null | undefined
}

interface SetIsIncrease {
  type: typeof SET_IS_INCREASE
  payload: boolean
}

interface SetHours {
  type: typeof SET_HOURS
  payload: number
}

interface SetFinancialYear {
  type: typeof SET_FINANCIAL_YEAR
  payload: string
}

interface SetEmployeeArray {
  type: typeof SET_EMPLOYEE_ARRAY
  payload: object
}

interface SetEmployeeSelected {
  type: typeof SET_EMPLOYEE_SELECTED
  payload: number | null
}

interface SetAbsenceArray {
  type: typeof SET_ABSENCE_ARRAY
  payload: FlattenedRequest[]
}

interface SetAbsenceArrayDirectReports {
  type: typeof SET_ABSENCE_ARRAY_DIRECT_REPORTS
  payload: FlattenedRequest[]
}

interface SetDirectReportsToggle {
  type: typeof SET_DIRECT_REPORTS_TOGGLE
  payload: boolean
}

interface SetFilteredAbsences {
  type: typeof SET_FILTERED_ABSENCES
  payload: FlattenedRequest[] | undefined
}

interface SetTabSelected {
  type: typeof SET_TAB_SELECTED
  payload: string
}

interface SetAlertsAction {
  type: typeof SET_ALERTS
  payload: ConflictMatch[] | null
}

interface SetConflictsAction {
  type: typeof SET_CONFLICTS
  payload: boolean
}

interface SetLateData {
  type: typeof SET_LATE_DATA
  payload: any
}

interface SetActionFilterSelected {
  type: typeof SET_ACTION_FILTER_SELECTED
  payload: any
}

interface SetCalloutDetails {
  type: typeof SET_CALLOUT_DETAILS
  payload: CalloutDetails
}

type timeOffRequestsDispatchtypes =
  | SetDateRange
  | SetDefaultDateRange
  | SetSelectedDays
  | SetComments
  | SetDate
  | SetTime
  | SetRequestType
  | ResetNewRequestState
  | SetEmployees
  | SetIsIncrease
  | SetHours
  | SetFinancialYear
  | SetEmployeeArray
  | SetEmployeeSelected
  | SetAbsenceArray
  | SetAbsenceArrayDirectReports
  | SetDirectReportsToggle
  | SetFilteredAbsences
  | SetTabSelected
  | SetAlertsAction
  | SetConflictsAction
  | SetLateData
  | SetActionFilterSelected
  | SetCalloutDetails

// Actions
export const setDateRange = (dateRange: DateRange<Date>): SetDateRange => ({
  type: SET_DATE_RANGE,
  payload: dateRange,
})

export const setDefaultDateRange = (
  dateRange: DateRange<Date> | undefined
): SetDefaultDateRange => ({ type: SET_DEFAULT_DATE_RANGE, payload: dateRange })

export const setSelectedDays = (selectedDays: AbsenceDayRequest[] | null): SetSelectedDays => ({
  type: SET_SELECTED_DAYS,
  payload: selectedDays,
})

export const setComments = (comments: string): SetComments => ({
  type: SET_COMMENTS,
  payload: comments,
})

export const setDate = (date: Date | null): SetDate => ({
  type: SET_DATE,
  payload: date,
})

export const setTime = (date: Date | null): SetTime => ({
  type: SET_TIME,
  payload: date,
})

export const setRequestType = (requestType: RequestType): SetRequestType => ({
  type: SET_REQUEST_TYPE,
  payload: requestType,
})

export const resetNewRequestState = (): ResetNewRequestState => ({
  type: RESET_NEW_REQUEST_STATE,
})

export const setEmployees = (employees: EmployeeDetails[] | null | undefined): SetEmployees => ({
  type: SET_EMPLOYEES,
  payload: employees,
})

export const setIsIncrease = (isIncrease: boolean): SetIsIncrease => ({
  type: SET_IS_INCREASE,
  payload: isIncrease,
})

export const setHours = (adjustmentHours: number): SetHours => ({
  type: SET_HOURS,
  payload: adjustmentHours,
})

export const setFinancialYear = (financialYear: string): SetFinancialYear => ({
  type: SET_FINANCIAL_YEAR,
  payload: financialYear,
})

export const setEmployeeArray = (employee: object): SetEmployeeArray => ({
  type: SET_EMPLOYEE_ARRAY,
  payload: employee,
})

export const setEmployeeSelected = (employeeSelected: number | null): SetEmployeeSelected => ({
  type: SET_EMPLOYEE_SELECTED,
  payload: employeeSelected,
})

export const setAbsenceArray = (absenceArray: FlattenedRequest[]): SetAbsenceArray => ({
  type: SET_ABSENCE_ARRAY,
  payload: absenceArray,
})

export const setAbsenceArrayDirectReports = (
  absenceArrayDirectReports: FlattenedRequest[]
): SetAbsenceArrayDirectReports => ({
  type: SET_ABSENCE_ARRAY_DIRECT_REPORTS,
  payload: absenceArrayDirectReports,
})

export const setDirectReportsToggle = (directReportsToggle: boolean): SetDirectReportsToggle => ({
  type: SET_DIRECT_REPORTS_TOGGLE,
  payload: directReportsToggle,
})

export const setFilteredAbsences = (
  filteredAbsences: FlattenedRequest[] | undefined
): SetFilteredAbsences => ({
  type: SET_FILTERED_ABSENCES,
  payload: filteredAbsences,
})

export const setTabSelected = (selected: string): SetTabSelected => ({
  type: SET_TAB_SELECTED,
  payload: selected,
})

export const setAlerts = (alerts: ConflictMatch[] | null): SetAlertsAction => ({
  type: SET_ALERTS,
  payload: alerts,
})

export const setConflicts = (conflicts: boolean): SetConflictsAction => ({
  type: SET_CONFLICTS,
  payload: conflicts,
})

export const setLateData = (lateInfo: any): SetLateData => ({
  type: SET_LATE_DATA,
  payload: lateInfo,
})

export const setActionFilterSelected = (actionFilter: string): SetActionFilterSelected => ({
  type: SET_ACTION_FILTER_SELECTED,
  payload: actionFilter,
})

export const setCalloutDetails = (calloutDetails: CalloutDetails): SetCalloutDetails => ({
  type: SET_CALLOUT_DETAILS,
  payload: calloutDetails,
})

// Reducer
export const timeOffRequestsReducer = (
  state: timeOffRequestsState = initialState,
  action: timeOffRequestsDispatchtypes
): timeOffRequestsState => {
  switch (action.type) {
    case SET_DATE_RANGE:
      return { ...state, dateRange: action.payload }
    case SET_DEFAULT_DATE_RANGE:
      return { ...state, defaultDateRange: action.payload }
    case SET_SELECTED_DAYS:
      return { ...state, selectedDays: action.payload }
    case SET_COMMENTS:
      return { ...state, comments: action.payload }
    case SET_DATE:
      return { ...state, selectedDate: action.payload }
    case SET_TIME:
      return { ...state, selectedTime: action.payload }
    case SET_REQUEST_TYPE:
      return { ...state, requestType: action.payload }
    case RESET_NEW_REQUEST_STATE:
      return {
        dateRange: [null, null],
        defaultDateRange: state.defaultDateRange,
        selectedDays: null,
        selectedDate: null,
        selectedTime: null,
        comments: '',
        requestType: RequestType.HOLIDAY,
        employees: [],
        isIncrease: false,
        adjustmentHours: 0,
        financialYear: ``,
        employeeArray: [],
        employeeSelected: null,
        absenceArray: state.absenceArray,
        absenceArrayDirectReports: state.absenceArrayDirectReports,
        directReportsToggle: state.directReportsToggle,
        filteredAbsences: state.filteredAbsences,
        tabSelected: 'All',
        alerts: null,
        conflicts: false,
        lateData: null,
        actionFilterSelected: '',
        calloutDetails: calloutInitialState,
      }
    case SET_EMPLOYEES:
      return { ...state, employees: action.payload }
    case SET_IS_INCREASE:
      return { ...state, isIncrease: action.payload }
    case SET_HOURS:
      return { ...state, adjustmentHours: action.payload }
    case SET_FINANCIAL_YEAR:
      return { ...state, financialYear: action.payload }
    case SET_EMPLOYEE_ARRAY:
      return { ...state, employeeArray: action.payload }
    case SET_EMPLOYEE_SELECTED:
      return { ...state, employeeSelected: action.payload }
    case SET_ABSENCE_ARRAY:
      return { ...state, absenceArray: action.payload }
    case SET_DIRECT_REPORTS_TOGGLE:
      return { ...state, directReportsToggle: action.payload }
    case SET_FILTERED_ABSENCES:
      return { ...state, filteredAbsences: action.payload }
    case SET_TAB_SELECTED:
      return { ...state, tabSelected: action.payload }
    case SET_ALERTS:
      return { ...state, alerts: action.payload }
    case SET_CONFLICTS:
      return { ...state, conflicts: action.payload }
    case SET_LATE_DATA:
      return { ...state, lateData: action.payload }
    case SET_ABSENCE_ARRAY_DIRECT_REPORTS:
      return { ...state, absenceArrayDirectReports: action.payload }
    case SET_ACTION_FILTER_SELECTED:
      return { ...state, actionFilterSelected: action.payload }
    case SET_CALLOUT_DETAILS:
      return { ...state, calloutDetails: action.payload }
    default:
      return state
  }
}
