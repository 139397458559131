import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { RequestType } from '../../models'
import { hideHeaderDrawer } from '../../redux/reducers/appSettingsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import Alert from '../../shared/UI/Alert/Alert'
import Button from '../../shared/UI/Button'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import HeadingTwo from '../../shared/UI/HeadingTwo'
import Paragraph from '../../shared/UI/Paragraph'

import { callOutFromContent, globalContent, absenceFormLabels } from '../../utils/constants'
import DateTimePicker from '../../shared/UI/DateTimePicker'
import ManagerEmployeeDropDown from '../MultiDayRequest/ManagerEmployeeDropDown'
import { PayPeriodContent } from '../../shared/UI/PayPeriodContent/PayPeriodContent'
import { StatusChip } from '../../shared/UI/StatusChip/StatusChip'
import { RowRadioGroup } from './RowRadioGroup/RowRadioGroup'
import { ValidaitonErrors, validateFormValues } from './validations'
import {
  setCalloutDetails,
  setDateRange,
  setRequestType,
  timeOffRequestsState,
} from '../../redux/reducers/timeOffRequestsReducer'
import { getDurationInFractionalHours } from '../../utils/date-utils'
import { contentState, setCallOutMenuItems } from '../../redux/reducers/contentReducer'
import { enhancementService } from '../../services/enhancementService'

const enhancementType = RequestType.CALL_OUT_M
export const callOutTestId = 'callout'

export interface CallOutEnhancementProps {
  isManager?: boolean
  isEmployeeSelected?: boolean
  disabledDates?: string[]
  submitLoading?: boolean
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, type: RequestType) => void
  handleClose?: (event: React.FormEvent<HTMLFormElement>, submission?: boolean) => void
}

export function CallOutEnhancement({
  isManager,
  disabledDates,
  isEmployeeSelected,
  submitLoading,
  handleSubmit,
  handleClose,
}: CallOutEnhancementProps) {
  const dispatch = useAppDispatch()
  // States
  const [formErrors, setFormErrors] = useState<ValidaitonErrors>()
  const [duration, setDuration] = useState<number>(0)
  const { dateRange, calloutDetails } = useSelector<RootStore, timeOffRequestsState>(
    (state: RootStore) => state.timeOff
  )
  const { callOutMenuItems } = useSelector<RootStore, contentState>(
    (state: RootStore) => state.content
  )

  /**
   * Check if the selected date time is in 12 hours range.
   */
  const validateAndSetDuration = () => {
    const [start, end] = dateRange
    if (start && end) {
      const hours = getDurationInFractionalHours(start, end)
      setFormErrors(prevState => ({
        ...prevState,
        duration: hours <= 0 || hours > 12,
      }))
      setDuration(hours)
    }
  }
  /**
   * Set enhancement request type on component render
   */
  useEffect(() => {
    if (isManager) {
      dispatch(setRequestType(RequestType.CALL_OUT_M))
    } else {
      dispatch(setRequestType(RequestType.CALL_OUT))
    }
  }, [])

  /**
   * Fetch callout menu items if not present in the store
   */
  useEffect(() => {
    if (!callOutMenuItems)
      enhancementService
        .getCallOutMenuItems()
        .then(response => {
          dispatch(setCallOutMenuItems(response))
        })
        .catch(error => {
          console.error('Failed to fetch menu items', error)
        })
  }, [callOutMenuItems])

  /**
   * Revalidate when date range changes
   */
  useEffect(() => {
    validateAndSetDuration()
  }, [dateRange])

  const renderPayPeriod = (
    <Grid item xs={12} display="flex" justifyContent="space-between">
      <Typography variant="body2" color="#000000">
        <PayPeriodContent payPeriod={3} dateRange={{ start: '2024-05-01', end: '2024-05-31' }} />
      </Typography>
      <StatusChip label="Submit by: 9:15 on 13th December" type="pending" />
    </Grid>
  )

  const renderAutocompleteInput = (params: AutocompleteRenderInputParams, label: string) => (
    <TextField
      {...params}
      label={callOutFromContent[label]}
      variant="outlined"
      error={formErrors?.[label]}
      helperText={globalContent.required}
      fullWidth
    />
  )
  return (
    <Grid
      component="form"
      noValidate
      container
      columnSpacing={6}
      rowSpacing={4}
      data-testid={callOutTestId}
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const { isValid, validaitonErrors } = validateFormValues(dateRange, calloutDetails)
        setFormErrors(validaitonErrors)
        if (isValid) {
          handleSubmit(event, enhancementType)
        }
      }}
    >
      {isManager && (
        <>
          <Grid item xs={12} lg={6}>
            <ManagerEmployeeDropDown />
          </Grid>
          <Grid item xs={12} lg={6}>
            {!isEmployeeSelected && (
              <Alert severity="info" message={absenceFormLabels.selectEmployee} />
            )}
          </Grid>
        </>
      )}
      {renderPayPeriod}
      {isEmployeeSelected || !isManager ? (
        <>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Paragraph weight="bold">{globalContent.details}</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  value={dateRange[0]}
                  maxDate={new Date()}
                  onChange={date => dispatch(setDateRange([date, dateRange[1]]))}
                  label={globalContent.from}
                  error={formErrors?.start}
                  helperText={globalContent.required}
                  disabledDates={disabledDates}
                  dataTestId={`${callOutTestId}-from-date`}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  value={dateRange[1]}
                  maxDate={new Date()}
                  onChange={date => dispatch(setDateRange([dateRange[0], date]))}
                  label={globalContent.to}
                  error={formErrors?.end}
                  helperText={globalContent.required}
                  disabledDates={disabledDates}
                  dataTestId={`${callOutTestId}-to-date`}
                />
                {formErrors?.duration ? (
                  <Alert severity="error" message={callOutFromContent.durationError} />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.department || []}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'calledOutBy')}
                  data-testid={`${callOutTestId}-department-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, calledOutBy: value }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.issueType || []}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'issueType')}
                  data-testid={`${callOutTestId}-issueType-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, issueType: value }))
                  }
                />
              </Grid>
              <RowRadioGroup
                value={calloutDetails.escalationRequired}
                label={callOutFromContent.escalated}
                onRadioChange={newValue =>
                  dispatch(setCalloutDetails({ ...calloutDetails, escalationRequired: newValue }))
                }
                tooltipText={callOutFromContent.escalatedToTooltip}
                testid={`${callOutTestId}-radio-escalated`}
              />
              {calloutDetails.escalationRequired ? (
                <>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={callOutMenuItems?.escalationType || []}
                      getOptionLabel={option => option}
                      renderInput={params => renderAutocompleteInput(params, 'escalationType')}
                      data-testid={`${callOutTestId}-escalationType-dropdown`}
                      onInputChange={(_e, value) =>
                        dispatch(setCalloutDetails({ ...calloutDetails, escalationType: value }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={callOutMenuItems?.escalateTo || []}
                      getOptionLabel={option => option}
                      renderInput={params => renderAutocompleteInput(params, 'escalatedTo')}
                      data-testid={`${callOutTestId}-escalatedTo-dropdown`}
                      onInputChange={(_e, value) =>
                        dispatch(setCalloutDetails({ ...calloutDetails, escalatedTo: value }))
                      }
                    />
                  </Grid>
                </>
              ) : null}
              <RowRadioGroup
                value={calloutDetails.transferToAnotherTeam}
                label={callOutFromContent.transferredToTeam}
                onRadioChange={newValue =>
                  dispatch(
                    setCalloutDetails({ ...calloutDetails, transferToAnotherTeam: newValue })
                  )
                }
                testid={`${callOutTestId}-radio-transferredToTeam`}
              />
              {calloutDetails.transferToAnotherTeam ? (
                <Grid item xs={12}>
                  <Autocomplete
                    options={callOutMenuItems?.team || []}
                    getOptionLabel={option => option}
                    renderInput={params => renderAutocompleteInput(params, 'team')}
                    data-testid={`${callOutTestId}-team-dropdown`}
                    onInputChange={(_e, value) =>
                      dispatch(setCalloutDetails({ ...calloutDetails, team: value }))
                    }
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Paragraph weight="bold">{globalContent.requestSummary}</Paragraph>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.conclusion || []}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'conclusion')}
                  data-testid={`${callOutTestId}-conclusion-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, conclusion: value }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  onChange={e =>
                    dispatch(
                      setCalloutDetails({ ...calloutDetails, jiraReference: e.target.value })
                    )
                  }
                  id="jiraReference"
                  label={callOutFromContent.jiraReference}
                  helperText={globalContent.required}
                  error={formErrors?.jiraReference}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={callOutMenuItems?.product || []}
                  getOptionLabel={option => option}
                  renderInput={params => renderAutocompleteInput(params, 'product')}
                  data-testid={`${callOutTestId}-product-dropdown`}
                  onInputChange={(_e, value) =>
                    dispatch(setCalloutDetails({ ...calloutDetails, product: value }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="reason"
                  label={callOutFromContent.reason}
                  fullWidth
                  rows={6}
                  multiline
                  onChange={e =>
                    dispatch(setCalloutDetails({ ...calloutDetails, reason: e.target.value }))
                  }
                  helperText={globalContent.required}
                  error={formErrors?.reason}
                />
              </Grid>
              <RowRadioGroup
                value={calloutDetails.codeChange}
                label={callOutFromContent.codeChangeReleased}
                onRadioChange={newValue =>
                  dispatch(setCalloutDetails({ ...calloutDetails, codeChange: newValue }))
                }
                testid={`${callOutTestId}-radio-codeChange`}
              />
              <RowRadioGroup
                value={calloutDetails.logInRequired}
                label={callOutFromContent.logInRequired}
                onRadioChange={newValue => {
                  dispatch(setCalloutDetails({ ...calloutDetails, logInRequired: newValue }))
                }}
                testid={`${callOutTestId}-radio-logInRequired`}
                tooltipText={callOutFromContent.loginRequiredTooltip}
              />

              {duration ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 'auto',
                    flexGrow: 1,
                  }}
                >
                  <HeadingTwo title={globalContent.duration} />
                  <HeadingTwo title={`${duration.toFixed(2)} Hours`} />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </>
      ) : null}

      {isEmployeeSelected || !isManager ? (
        <DrawerFooter>
          <Button
            color="secondary"
            label="Cancel"
            onClick={event => {
              dispatch(hideHeaderDrawer())
              handleClose?.(event, false)
            }}
          />
          <Button label="Submit" type="submit" loading={submitLoading} />
        </DrawerFooter>
      ) : null}
    </Grid>
  )
}
