import { useCallback, useEffect, useRef } from "react"

const useAbortController = () => {
  const abortControllerRef = useRef<AbortController | null>(null)

  useEffect(() => () => {
    abortControllerRef.current?.abort()
  }, [])

  const abortAndReplaceController = useCallback(() => {
    abortControllerRef?.current?.abort()
    abortControllerRef.current = new AbortController()
  }, [])

  return {
    abortControllerRef,
    abortAndReplaceController
  }
}

export default useAbortController