import { ChevronLeft } from '@mui/icons-material'
import useBackButton from './useBackButton'
import ButtonSmall from '../ButtonSmall'

function BackButtonMobile() {
  const { handleNavigation } = useBackButton()

  return (
    <ButtonSmall
      label="Back"
      onClick={() => {
        handleNavigation()
      }}
      startIcon={<ChevronLeft />}
      minWidth="auto"
      sx={{ height: '28px', lineHeight: '28px' }}
    />
  )
}

export default BackButtonMobile
