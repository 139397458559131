import { Typography as MuiTypography, Stack, Chip } from '@mui/material'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import theme from '../../theme/theme'
import { RootStore, useAppDispatch } from '../../redux/store'
import { setActionFilterSelected } from '../../redux/reducers/timeOffRequestsReducer'

type Props = {
  absenceLabel: string
  absenceType: string
  getCount: (e: string) => number | undefined
}

function IndividualAbsenceType({ absenceLabel, absenceType, getCount }: Props) {
  const selected = useSelector<RootStore, string>((state: RootStore) => state.timeOff.tabSelected)
  const dispatch = useAppDispatch()

  const StyledLink = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    color: ${selected === absenceType
      ? theme.palette.primary.main
      : theme.palette.text.primary}!important;
    &:hover {
      color: ${theme.palette.primary.main}!important;
    }
  `

  const StyledChip = styled(Chip)`
    background: ${selected === absenceType ? theme.palette.primary.main : '#fff'}!important;
    color: ${selected === absenceType ? '#fff' : theme.palette.text.primary}!important;
    cursor: pointer !important;
  `

  const StyledTypography = styled(MuiTypography)`
    font-size: 13px !important;
    letter-spacing: 0.2px !important;
  `

  return (
    <StyledLink
      to="#"
      onClick={() => {
        dispatch(setActionFilterSelected(absenceType))
      }}
    >
      <Stack
        direction="row"
        minWidth={110}
        gap={2}
        justifyContent="space-between"
        alignItems="center"
        data-testid={`MyActions-${absenceType}`}
      >
        <StyledTypography>{absenceLabel}</StyledTypography>
        <StyledChip label={getCount(absenceType)} />
      </Stack>
    </StyledLink>
  )
}

export default IndividualAbsenceType
