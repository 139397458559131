import React from 'react'
import { dateOrdinal } from '../../../utils/app-utils'
import { getLongMonthString } from '../../../utils/date-utils'

interface PayPeriodContentProps {
  payPeriod: number
  dateRange: {
    start: string
    end: string
  }
}
const contentStyles = {
  marginRight: '24px',
  fontSize: '14px',
  fontWeight: '700',
  letterSpacing: '-0.56px',
  color: 'inherit',
}
/**
 * Converts given day and date to string date with superscrited ordinal
 * e.g  13, new Date('13-12-2024') => 13<sup>th</sup> December
 */
const toOrdinalDateAndMonthString = (date: Date) => {
  const actualDate = new Date(date)
  const day = actualDate.getDate()

  return (
    <>
      {day}
      <sup>{`${dateOrdinal(day)} `}</sup>
      {getLongMonthString(actualDate)}
    </>
  )
}
export function PayPeriodContent({ dateRange, payPeriod }: PayPeriodContentProps) {
  const { start, end } = dateRange
  return (
    <span data-testid="pay-period-content">
      <span style={{ marginRight: '8px', fontWeight: 'bold' }}>{`Pay Period ${payPeriod}:`}</span>
      {toOrdinalDateAndMonthString(new Date(start))}
      {' - '}
      {toOrdinalDateAndMonthString(new Date(end))}
    </span>
  )
}
