import { useEffect, useState } from 'react'
import format from 'date-fns/format'
import { Box, Typography } from '@mui/material'
import { ReactComponent as ArrowUpIcon } from '../../assets/callout/arrow-up.svg'
import { ReactComponent as CalendarIcon } from '../../assets/callout/calendar.svg'
import { ReactComponent as UserCheckIcon } from '../../assets/callout/user-check.svg'
import { ReactComponent as MessageIcon } from '../../assets/callout/message-square.svg'
import { ReactComponent as LinkIcon } from '../../assets/callout/ios-link.svg'
import { DateRange, EnhancementGetResponse } from '../../models/enhancement'
import Paragraph from '../../shared/UI/Paragraph'
import { callOutFromContent } from '../../utils/constants'
import { v2MyActionsService } from '../../services/myActionsServiceV2'

export interface CallOutSummaryProps {
  enhancementId: number
}
const twoLinesWithElipsis = {
  fontSize: '11px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}
const titleStyles = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
  mb: 2,
  fontSize: '13px',
  color: '#000000',
}
const getContent = (
  dateRange: DateRange,
  jiraReference: string,
  calledOutBy: string,
  reason: string,
  escalationRequired: boolean
) => [
  {
    title: callOutFromContent.timeAndDate,
    icon: <CalendarIcon />,
    content: (
      <>
        <Paragraph size="11px">
          <b>Start date </b>
          {format(new Date(dateRange.start), 'Pp')}
        </Paragraph>
        <Paragraph size="11px">
          <b>End date </b>
          {format(new Date(dateRange.end), 'Pp')}
        </Paragraph>
      </>
    ),
  },
  {
    title: callOutFromContent.jiraReference,
    icon: <LinkIcon />,
    content: <Typography sx={twoLinesWithElipsis}>{jiraReference}</Typography>,
  },
  {
    title: callOutFromContent.calledOut,
    icon: <UserCheckIcon />,
    content: <Paragraph size="11px">{calledOutBy}</Paragraph>,
  },
  {
    title: callOutFromContent.description,
    icon: <MessageIcon />,
    content: <Typography sx={twoLinesWithElipsis}>{reason}</Typography>,
  },
  {
    title: callOutFromContent.requireEscalation,
    icon: <ArrowUpIcon />,
    content: <Paragraph size="11px">{escalationRequired ? 'Yes' : 'No'}</Paragraph>,
  },
]

export function CallOutSummary({ enhancementId }: CallOutSummaryProps) {
  const [content, setContent] = useState<
    { title: string; icon: JSX.Element; content: JSX.Element }[]
  >([])
  useEffect(() => {
    v2MyActionsService
      .getEnhancementForManager(enhancementId)
      .then((response: EnhancementGetResponse) => {
        const { calloutDetails, dateRange }: EnhancementGetResponse = response
        if (calloutDetails) {
          setContent(
            getContent(
              dateRange,
              calloutDetails.jiraReference || '',
              calloutDetails.calledOutBy || '',
              calloutDetails.reason || '',
              calloutDetails.escalationRequired || false
            )
          )
        }
      })
      .catch(() => console.error('Failed to getEnhancementForManager'))
  }, [])

  return (
    <Box
      display="flex"
      gap={2}
      width="70%"
      margin="auto"
      padding="16px 0"
      data-testid={`callout-summary-${enhancementId}`}
    >
      {content.map(item => (
        <Box sx={{ width: '20%' }} key={item.title}>
          <Typography sx={titleStyles}>
            {item.icon}
            <b>{` ${item.title}`}</b>
          </Typography>
          {item.content}
        </Box>
      ))}
    </Box>
  )
}
