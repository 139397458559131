import { EnhancementStub } from '../../models/enhancement'
import { TrendIndicatorStats } from '../../shared/UI/TrendIndicatorCard/TrendIndicator'
import { enhancementFilter } from './enhancementSummaryFilter'

export function getStats(
  enhancements: EnhancementStub[],
  previousTotal: number,
  type: string | undefined
): TrendIndicatorStats {
  const array = type ? [type] : []
  const currentTotal = enhancementFilter(enhancements, array).reduce(
    (total, enhancement) => (enhancement?.hours ? total + enhancement.hours : total),
    0
  )

  let percentDiffernce
  if (!previousTotal && !currentTotal) percentDiffernce = 0
  else
    percentDiffernce =
      previousTotal === 0 ? 100 : Math.round((currentTotal / previousTotal) * 100 - 100)

  const hours = Number((Math.round(currentTotal * 4) / 4).toFixed(2))

  return { hours, percentage: percentDiffernce }
}
