import { Chip as MuiChip } from '@mui/material'
import styled from 'styled-components'
import { ColourSchemeProps } from '../../../components/LegendsFooter/types'
import { getPalleteTypeByProp } from '../../../theme/palette'

type Props = {
  label: string
  type: string
  testId?: string
  onClick?: () => void
}

interface StyledChipProps {
  type: string
  pending: string
}

const colorScheme = (eventType: string): ColourSchemeProps => {
  const palletObject = getPalleteTypeByProp(eventType.toLowerCase())
  if (!palletObject) {
    return { backgroundColour: 'lightgray', fontColour: 'gray', backgroundImage: 'unset' }
  }
  return {
    backgroundColour: palletObject.color,
    fontColour: palletObject.fontColor,
  }
}

const getIsPendingCSS = (type: string) => {
  if (type === 'Pending Requests')
    return `
    background-image: linear-gradient(135deg, transparent  37.50%, #d1d1d1 37.50%, #d1d1d1 50%, transparent  50%, transparent  87.50%, #d1d1d1 87.50%, #d1d1d1 100%);
    background-size: 10px 10px;
    color: black;`
}

const StyledChip = styled(MuiChip)<StyledChipProps>`
  &.MuiChip-root {
    font-weight: 400;
    font-size: 11px;
    color: #ffffff;
    letter-spacing: 0.2px;
    border-radius: 4px;
    padding: 4px 0px;
    height: auto;
    ${({ type }) => getIsPendingCSS(type)}
    ${({ type }) => `background-color: ${colorScheme(type).backgroundColour};`})
  }
`

function TypeLabel({ label, type, testId, onClick }: Props) {
  return (
    <StyledChip type={type} label={label} onClick={onClick} pending={type} data-testid={testId} />
  )
}

export default TypeLabel
