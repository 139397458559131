import { eachDayOfInterval, format, subMinutes } from 'date-fns'
import { Dispatch } from 'redux'

import { AnyAction } from 'redux'
import {
  BookingLocation,
  BookingBookingPostBody,
  BookingFeature,
  BookingFloorPlan,
  InitialBookingFeature,
  BookingBookingPayload,
  DatesFromBookings,
} from '../../../services/booking/types'
import { BookingSearchParams } from '../types'

import { BookingNewBooking } from '../Popover/types'
import { bookingService } from '../../../services/booking/bookingService'
import { formatDatTimeWithTimeZoneStr, formatDateWithTimeZone } from '../../../utils/date-utils'
import { showSuccessMessage } from '../../../redux/reducers/snackbarReducer'
import { BaseResponse } from '../../../types/base-response'
import { dateToNumber, getJson } from '../utils/utils'
import { getJsonFromPath } from '../../../utils/app-utils'
import { BookingAvailabilityColors, BookingDateRange, BookingAvability } from '../consts'
import { BookingWeekdays } from './types'

import {
  getDashboardBookings,
  onBookingGridViewDataLoaded,
  refreshForwardBookingsCount,
  refreshMostUsedDesk,
} from '../bookingLogic'

import { setBookingSearchRecentParams } from '../../../redux/reducers/deskBookingSearchReducer'
import { setSubmissionsInProgress } from '../../../redux/reducers/deskBookingStatusesReducer'

export const handleSingleBooking = async (
  feature: BookingFeature,
  deskName: string,
  { selectedRange, date }: BookingNewBooking,
  locations: BookingLocation[],
  floorplans: BookingFloorPlan[],
  employeeId: number,
  searchParams: BookingSearchParams,
  userName: string,
  floorplanViewingDate: Date,
  dispatch: Dispatch<AnyAction>,
  onError: (err: string) => void,
  submissionsInProgress: number[]
) => {
  const floorPlanName = floorplans.find(f => f.id === feature.floorPlanId)?.name
  const newBooking: Partial<BookingBookingPostBody> = {
    createdByDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    createdByEmployeeId: employeeId,
    employeeName: searchParams.employeeName || userName || '',
    employeeId: Number(searchParams.employeeID) > 0 ? searchParams.employeeID : employeeId,
    featureId: feature.id,
    deskDetails: {
      deskName,
      floorPlanName: floorPlanName || '',
      location: locations.find(f => f.id === feature.locationId)?.name || '',
    },
    floorPlanId: feature.floorPlanId,
    fromDate: format(date, 'yyyy-MM-dd'),
    fromTime: selectedRange.from,
    toDate: format(date, 'yyyy-MM-dd'),
    toTime: selectedRange.to,
    status: 'Accepted',
  }
  return bookingService
    .createBooking(newBooking)
    .then(result => {
      setTimeout(() => {
        dispatch(setSubmissionsInProgress(submissionsInProgress.filter(f => f !== feature.id)))
      }, 1000)
      dispatch(
        setBookingSearchRecentParams({
          fromDateTime: formatDatTimeWithTimeZoneStr(subMinutes(new Date(), 1)),
          date: format(floorplanViewingDate, 'yyyy-MM-dd'),
          floorPlanID: feature.floorPlanId,
          from: selectedRange.from,
          to: selectedRange.to,
        })
      )
      dispatch(
        showSuccessMessage(
          `You have successfully booked desk ${deskName} ${
            floorPlanName ? `in ${floorPlanName}` : ''
          }`
        )
      )
      refreshForwardBookingsCount()
      refreshMostUsedDesk()

      const singleDayBookingQuery = true
      getDashboardBookings(
        employeeId,
        floorplanViewingDate,
        dispatch,
        onBookingGridViewDataLoaded,
        singleDayBookingQuery
      )
    })
    .catch(err => {
      dispatch(setSubmissionsInProgress(submissionsInProgress.filter(f => f !== feature.id)))
      const response: BaseResponse = err.response.data
      response.errors.forEach(error => {
        onError(error.name)
      })
    })
}

export const getColor = (feature: BookingFeature) => {
  let fillColor = feature.fill
  if (fillColor.indexOf('zone') >= 0) {
    if (!feature?.zone?.additionalInfo) {
      fillColor = '#00000096'
    } else {
      const zoneAdditionalInfo = getJson(feature.zone.additionalInfo)
      fillColor = getJsonFromPath(zoneAdditionalInfo, fillColor.replace('zone.', ''))
    }
  }
  return fillColor
}

export const initialPopoverProps = () => {
  const date = new Date()
  return {
    svgElement: undefined,
    open: false,
    x: 0,
    y: 0,
    featureDetail: InitialBookingFeature,
    isOwnBooking: false,
    isBlockBooking: false,
    isManager: false,
    floorplan: '',
    availability: BookingAvability.AVAILABLE,
    availabilityColor: BookingAvailabilityColors.AVAILABLE,
    dateTimeFrom: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0),
    dateTimeTo: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 0),
  }
}

export const getDatesFromRange = (
  bookingRange: [Date, Date]
): { date: Date; dateNumber: number }[] =>
  eachDayOfInterval({
    start: formatDateWithTimeZone(bookingRange[BookingDateRange.FROM]),
    end: formatDateWithTimeZone(bookingRange[BookingDateRange.TO]),
  }).map(m => ({
    date: formatDateWithTimeZone(m),
    dateNumber: dateToNumber(formatDateWithTimeZone(m)),
  }))

export const getDatesFromBookings = (
  bookings: BookingBookingPayload[],
  weekdaysSelected?: BookingWeekdays[]
): DatesFromBookings[] =>
  bookings
    .filter(
      f =>
        f.statusId === 2 &&
        (!weekdaysSelected ||
          weekdaysSelected.some(s => s.dayIdx === new Date(f.fromDate).getDay()))
    )
    .map(m => {

      const {featureId, fromTime, toTime, fromDate} = m

      return {
        date: dateToNumber(fromDate),
        featureId,
        fromTime,
        toTime
      }
    })

export const getPortColor = (
  datesFromRange: number[],
  dateFromBookings: number[]
): BookingAvailabilityColors => {
  const datesWithBookings = datesFromRange.filter(x => dateFromBookings.includes(x))
  const datesWithoutBookings = datesFromRange.filter(x => !dateFromBookings.includes(x))
  if (datesWithoutBookings.length > 0 && datesWithBookings.length > 0) {
    return BookingAvailabilityColors.PARTIALLY_AVAILABLE
  }
  if (datesWithoutBookings.length === 0) {
    return BookingAvailabilityColors.NOT_AVAILABLE
  }
  return BookingAvailabilityColors.AVAILABLE
}

export const sortFeatures = (features: BookingFeature[]) =>
  features.sort((a, b) => {
    if (a.typeId < b.typeId) {
      return -1
    }
    if (a.typeId > b.typeId) {
      return 1
    }
    return 0
  })
