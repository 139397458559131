import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { Grid, Link, Typography } from '@mui/material'
import Card from '../../../shared/layout/Card'
import TypeLabel from '../../../shared/UI/TypeLabel'
import { getPalleteTypeByProp } from '../../../theme/palette'
import Button from '../../../shared/UI/Button'
import { StyledTableCell, StyledTableHead } from './style'
import { enhancementsContent } from '../../../utils/constants'
import { EnhancementStub } from '../../../models/enhancement'

const baseUrl = 'enhancements'
const testid = 'enhancement-summary-'
const resolveToPath = (type: string, id: number) => {
  const resolver: { [key: string]: string } = {
    'on call': 'oncallrequest',
    'call out': 'calloutrequest',
    night: 'nightrequest',
    overtime: 'overtimerequest',
  }
  return `/${baseUrl}/${resolver[type.toLowerCase()]}/${id}`
}

export type EnhancementsSummaryTableProps = {
  data?: EnhancementStub[]
  title?: string
}

interface Column {
  id: 'displayName' | 'hours' | 'enhancementType' | 'comments'
  label: string
}
const columns: Column[] = [
  { id: 'displayName', label: 'Employee' },
  { id: 'hours', label: 'Hours' },
  { id: 'enhancementType', label: 'Type' },
  { id: 'comments', label: 'Comments' },
]

const cellContent = (columnId: string, row: EnhancementStub) => {
  let cell: string | number | unknown
  switch (columnId) {
    case 'displayName':
      cell = (
        <Link component="button" variant="body2" textAlign="left">
          {row[columnId]}
        </Link>
      )
      break
    case 'enhancementType':
      cell = <TypeLabel label={getPalleteTypeByProp(row[columnId]).label} type={row[columnId]} />
      break
    default:
      cell = row[columnId as keyof EnhancementStub]
  }
  return cell
}

export function EnhancementsSummaryTable({ data, title }: EnhancementsSummaryTableProps) {
  const navigate = useNavigate()
  const handleRowClick = (type: string, id: number) => {
    navigate(resolveToPath(type, id), { state: { previousLocation: `/${baseUrl}` } })
  }
  const renderRows = useMemo(
    () =>
      data?.map(row => (
        <TableRow
          hover
          tabIndex={-1}
          key={row.enhancementId}
          data-testid={`${testid}row-${row.enhancementId}`}
          onClick={() => handleRowClick(row.enhancementType, row.enhancementId)}
        >
          {columns.map(column => (
            <TableCell key={column.id}>{cellContent(column.id, row)}</TableCell>
          ))}
        </TableRow>
      )),
    [data]
  )

  if (!data) {
    return <Typography variant="h5">{enhancementsContent.noData}</Typography>
  }

  return (
    <Card title={title} padding="24px 28px" noDivider>
      <TableContainer sx={{ maxHeight: 440, marginTop: '14px' }} data-testid={`${testid}table`}>
        <Table stickyHeader aria-label="enhancement summary table">
          <StyledTableHead>
            <TableRow>
              {columns.map(column => (
                <StyledTableCell key={column.id}>{column.label}</StyledTableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button label={enhancementsContent.submitToPayroll} type="submit" />
      </Grid>
    </Card>
  )
}
